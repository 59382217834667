#page-values-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: sticky;
  top: 179px;
  width: 925px;
  min-height: min(750px, min-content);
  max-height: 100vh;
  outline: 4px solid var(--outline-color);
  background-color: #3295b9e4;
  box-shadow: var(--input-container-box-shadow);
  border-radius: 1px;
}

#page-values-header {
  font-size: 30px;
  color: rgb(236, 236, 236);
  margin: 10px 0 0 0 !important;
  padding: 0 !important;
  font-family: var(--default-font) !important;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
}

#values-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-height: min(700px, min-content);
}

.page-values-hr {
  margin: 10px 0 0 0;
  color: var(--outline-color);
  background-color: var(--outline-color);
  width: 100%;
  border-bottom: 4px solid var(--outline-color);
}
#page-values {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
  max-height: 100%;
}

.file-info {
  font-size: 18px;
  color: var(--p-yellow);
  text-align: center;
  font-family: var(--default-font);
  font-weight: var(--default-font-weight);
}

#chips-container {
  margin: 10px 0 10px 0;
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px 5px;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  border-top: 2px solid var(--outline-color);
  border-bottom: 2px solid var(--outline-color);
  background-color: rgba(46, 51, 53, 0.889);
}

.page-value-chip.valid-chip {
  color: rgb(236, 236, 236);
  font-weight: bold;
  background-color: rgba(28, 231, 38, 0.753);
  margin: 0px 5px;
  outline: 2px solid rgba(236, 236, 236, 0.888);
}

.page-value-chip.valid-chip:hover {
  background-color: rgba(10, 255, 22, 0.753);
  cursor: pointer;
}

.page-value-chip.invalid-chip {
  color: rgb(236, 236, 236);
  font-weight: bold;
  background-color: rgba(255, 0, 0, 0.753);
  margin: 0px 5px;
  outline: 2px solid rgba(236, 236, 236, 0.888);
}

.page-value-chip.invalid-chip:hover {
  background-color: rgba(255, 0, 0, 0.753);
  cursor: pointer;
}

.pdf-btns-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  min-width: 280px;
  width: auto;
  padding: 5px 0px;
  margin: 5px 0;
}

#validate-page-button {
  background-color: rgb(247, 150, 31);
  color: var(--btn-white-text);
  font-size: 14px;
  font-family: var(--default-font) !important;
  font-weight: var(--font-weight-bold);
  margin: 0 !important;
  padding: 5px 10px !important;
  height: 35px;
}

#validate-page-button:hover {
  background-color: rgb(223, 132, 20);
  cursor: pointer;
}

#pdf-submit {
  background-color: var(--btn-green);
  color: var(--btn-white-text);
  font-size: 13px;
  font-weight: var(--font-weight-bold);
  font-family: var(--default-font) !important;
  width: 80px;
  height: 35px;
  padding: 0;
}

#pdf-prev,
#pdf-next {
  background-color: rgb(22, 107, 138);
  color: var(--btn-white-text);
  font-size: 15px;
  width: 35px;
  height: 35px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.347);
}

#pdf-reset {
  background-color: #d20c0c;
  color: var(--btn-white-text);
  font-size: 15px;
  font-weight: var(--default-font-weight);
  width: 130px;
  height: 35px;
  padding: 0;
}

#pdf-prev-icon,
#pdf-next-icon {
  color: var(--btn-white-text);
  stroke: var(--btn-white-text);
  padding: 0;
  margin: 0;
  height: 18px;
  width: 18px;
}

#pdf-reset:hover {
  background-color: #af0707;
  cursor: pointer;
}

#pdf-prev:hover,
#pdf-next:hover {
  background-color: rgb(12, 92, 121);
  cursor: pointer;
}
#pdf-submit:hover {
  background-color: #40b60d;
  cursor: pointer;
}

#pdf-prev[disabled],
#pdf-next[disabled],
#pdf-submit[disabled],
#validate-page-button[disabled] {
  background-color: rgba(152, 152, 152, 0.383);
  color: rgba(236, 236, 236, 0.383);
  box-shadow: none !important;
  font-weight: bold;
  border: none;
  cursor: not-allowed !important;
  pointer-events: auto !important;
}

#pdf-prev[disabled] #pdf-prev-icon,
#pdf-next[disabled] #pdf-next-icon {
  color: rgba(228, 228, 228, 0.383);
  stroke: rgba(228, 228, 228, 0.383);
}

#pdf-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  margin-bottom: 10px;
}

.pdf-pagination#pdf-pagination .pdf-pagination-ul {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.pdf-pagination#pdf-pagination li {
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdf-pagination#pdf-pagination #pagination-item {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--default-font);
  text-align: center;
  font-size: 16px;
  color: var(--btn-white-text);
  font-weight: var(--font-weight-bold);
  padding: 0;
  margin: 0;
  min-height: 35px;
  min-width: 35px;
  max-width: 35px;
  max-height: 35px;
  border-radius: 50%;
  line-height: 1;
  letter-spacing: 0.5px;
}

.pdf-pagination#pdf-pagination #pagination-item.Mui-disabled {
  color: rgba(255, 255, 255, 0.774);
  cursor: not-allowed;
}

@media screen and (width <= 1450px) {
  #page-values-container {
    width: 525px;
  }
  .page-value-chip.valid-chip,
  .page-value-chip.invalid-chip {
    font-size: 11px;
    height: 22px;
  }

  #pdf-prev,
  #pdf-next,
  #pdf-submit,
  #pdf-reset {
    height: 30px;
    font-size: 13px !important;
  }

  #validate-page-button {
    font-size: 13px !important;
    height: 24px;
  }
}

@media screen and (1450px <= width < 1650px) {
  #page-values-container {
    max-width: 555px;
  }
}

@media screen and (1650px <= width < 1735px) {
  #page-values-container {
    max-width: 700px;
  }
}

@media screen and (1735px <= width < 1800px) {
  #page-values-container {
    max-width: 780px;
  }
}

@media screen and (1800px <= width < 1875px) {
  #page-values-container {
    max-width: 850px;
  }
}

@media screen and (1875px <= width < 2000px) {
  #page-values-container {
    width: 925px;
  }
}

@media screen and (width >= 2000px) {
  #page-values-container {
    width: 1150px;
  }
}
