.existing-users-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 350px;
  max-width: 380px;
  max-height: calc(100vh - 350px);
  height: auto;
  margin: 0;
  background-color: var(--main-blue);
  position: relative;
  box-shadow: var(--input-container-box-shadow);
  top: 40px;
  overflow-y: scroll;
  border-radius: 2px;
  outline: 3px solid var(--outline-color);
}

#existing-users-header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-bottom: 3px solid var(--outline-color);
  background-color: var(--main-blue);
  z-index: 999;
  position: sticky;
  top: 0;
  margin-bottom: 10px;
  padding: 0;
  height: 30px;
}

#existing-users-heading {
  font-size: 16px;
  font-family: var(--default-font);
  font-weight: var(--font-weight-bold);
  color: var(--main-header-color);
  text-shadow: var(--header-text-shadow);
  padding: 0;
}

.existing-users-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 92%;
  height: 100%;
  gap: 10px;
  margin: 0;
}

.existing-user {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100px;
  width: 100%;
  outline: 2px solid var(--outline-color);
  border-radius: 3px;
  padding: 2px;
  gap: 6px;
  background-color: var(--argus-blue-darker);
}

.user-data {
  color: var(--argus-white);
  font-size: 13px;
  font-family: var(--default-font);
  font-weight: var(--default-font-weight);
  margin: 0;
  padding: 0;
}

.user-data#users-name {
  color: var(--argus-white);
  font-size: 13px;
  font-family: var(--default-font);
  font-weight: var(--font-weight-bold);
  margin: 0;
  padding: 0;
  text-decoration: underline;
  text-transform: uppercase;
}

.existing-user-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: auto 0 5px 0;
}

.invite-user-btn#invite-user-btn {
  background-color: var(--btn-green) !important;
  color: var(--btn-white-text) !important;
  font-family: var(--default-font) !important;
  font-weight: var(--font-weight-bold) !important;
  line-height: 1 !important;
  font-size: 10px;
  padding: 0;
  height: 20px;
  width: 52px;
}

.invite-user-btn#invite-user-btn:hover {
  background-color: var(--btn-green-hover);
}

.delete-user-btn#delete-user-btn {
  background-color: var(--btn-red) !important;
  color: var(--btn-white-text) !important;
  font-family: var(--default-font) !important;
  font-weight: var(--font-weight-bold) !important;
  line-height: 1 !important;
  font-size: 10px;
  padding: 0;
  height: 20px;
  width: 52px;
}

.delete-user-btn#delete-user-btn:hover {
  background-color: var(--btn-red-hover);
}

@media screen and (height < 800px) {
  .existing-users-container {
    top: 26px;
  }
}

@media screen and (width < 1450px) {
  .existing-users-container {
    max-width: 325px;
    min-width: 310px;
  }

  #existing-users-header {
    height: 28px;
  }

  #existing-users-heading {
    font-size: 14px;
  }

  .existing-users-list {
    gap: 5px;
  }

  .existing-user {
    height: 90px;
    gap: 4px;
  }

  .user-data {
    font-size: 12px;
  }

  .user-data#users-name {
    font-size: 12px;
  }

  .invite-user-btn#invite-user-btn,
  .delete-user-btn#delete-user-btn {
    font-size: 10px;
    height: 20px;
    min-width: 55px !important;
    padding: 0 !important;
  }
}

@media screen and (1450px <= width < 1750px) {
  .existing-users-container {
    max-width: 325px;
    min-width: 320px;
  }

  #existing-users-heading {
    font-size: 14px;
  }

  .existing-user {
    height: 100px;
    gap: 5px;
  }

  .user-data {
    font-size: 13px;
  }

  .user-data#users-name {
    font-size: 13px;
  }

  .invite-user-btn#invite-user-btn,
  .delete-user-btn#delete-user-btn {
    font-size: 11px;
    height: 22px;
    width: 64px;
    line-height: 1 !important;
    padding: 0 !important;
  }
}
