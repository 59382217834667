.fileDetailsButton#fileDetailsButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  padding: 0;
  margin: 0;
  transition: 150ms ease;
}

.fileDetailsButton#fileDetailsButton:hover {
  background-color: transparent;
  /* background-color: #44779683; */
}

#fileDetailsIcon {
  color: #f3dda6;
  height: 32px;
  width: 32px;
  filter: drop-shadow(0px 1px 1px rgba(0, 31, 49, 0.616));
}

.contactsButtonRoot.contactsButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 45px !important;
  height: 45px !important;
  padding: 0;
  margin: 0;
  transition: 150ms ease;
  pointer-events: all;
}

.contactsButtonRoot.contactsButton:hover {
  background-color: transparent;
  /* background-color: #44779683; */
  cursor: pointer;
}

#contactsIcon {
  color: rgb(226, 226, 226);
  height: 32px !important;
  width: 32px !important;
  filter: drop-shadow(0px 1px 1px #001f319d);
  pointer-events: auto;
}

.emailUserButtonRoot.emailUserButton {
  height: 45px !important;
  width: 45px !important;
  margin: 0 !important;
  padding: 0 !important;
  transition: 550ms ease;
}

.emailUserButtonRoot.emailUserButton #emailUserIcon {
  color: rgb(226, 226, 226);
  height: 32px;
  width: 32px;
  padding: 0 !important;
  margin: 0 !important;
  filter: drop-shadow(0px 1px 1px #001f319d);
}

.emailUserButtonRoot.emailUserButton:hover {
  background-color: transparent;
  /* background-color: #44779683; */
  cursor: pointer;
}

.emailUserButtonRoot.emailUserButton.disabledEmailBtn {
  color: #c5c5c595 !important;
  cursor: not-allowed !important;
  pointer-events: all !important;
}

.emailUserButton.emailUserButton.disabledEmailBtn #emailUserIcon {
  color: #e7e7e7a3 !important;
  filter: none !important;
}

.emailUserButtonRoot.emailUserButton.disabledEmailBtn:hover {
  cursor: not-allowed !important;
  color: #c5c5c595 !important;
  background-color: transparent !important;
}

#editButtonAdmin.editButton {
  background-color: var(--argus-white) !important;
  color: rgb(46, 179, 227) !important;
  height: 30px;
  width: 70px !important;
  font-size: 14px;
  font-family: var(--default-font) !important;
  font-weight: var(--font-weight-bold) !important;
  transition: all 500ms ease 0s !important;
}

#editButtonAdmin.editButton:hover {
  background-color: rgb(52, 166, 208) !important;
  color: var(--argus-white) !important;
}

#inProgressButtonAdmin.inProgressButton {
  color: rgb(230, 114, 13);
  background-color: var(--argus-white);
  height: 30px;
  width: 70px !important;
  font-size: 14px;
  font-family: var(--default-font) !important;
  font-weight: var(--font-weight-bold) !important;
  transition: all 500ms ease 0s !important;
}

#inProgressButtonAdmin.inProgressButton:hover {
  background-color: rgb(230, 114, 13);
  color: var(--argus-white);
}

#archiveButtonAdmin.archiveButton {
  color: #4d535a;
  background-color: var(--argus-white);
  height: 30px;
  width: 72px !important;
  font-size: 14px;
  font-family: var(--default-font) !important;
  font-weight: var(--font-weight-bold) !important;
  letter-spacing: 0.1px;
  transition: all 500ms ease 0s !important;
}

#archiveButtonAdmin.archiveButton:hover {
  background-color: #4d535a;
  color: var(--argus-white);
}

#pendingButtonAdmin.pendingButton {
  color: rgb(125, 125, 125);
  background-color: var(--argus-white);
  height: 30px;
  width: 72px !important;
  font-size: 14px;
  font-family: var(--default-font) !important;
  font-weight: var(--font-weight-bold) !important;
  letter-spacing: 0.1px;
  transition: all 500ms ease 0s !important;
}

#pendingButtonAdmin.pendingButton:hover {
  background-color: rgb(145, 145, 145);
  color: var(--argus-white);
}

#reviseButtonAdmin.reviseButton {
  color: rgb(228, 61, 61);
  background-color: var(--argus-white);
  height: 30px;
  width: 72px !important;
  font-size: 14px;
  font-family: var(--default-font) !important;
  font-weight: var(--font-weight-bold) !important;
  letter-spacing: 0.1px;
  transition: all 500ms ease 0s !important;
}

#reviseButtonAdmin.reviseButton:hover {
  background-color: rgb(204, 71, 71);
  color: var(--argus-white);
}

#downloadButtonAdmin.downloadButton {
  color: rgb(46, 187, 31);
  background-color: var(--argus-white);
  height: 32px;
  width: 100px !important;
  font-size: 14px;
  font-family: var(--default-font) !important;
  font-weight: var(--font-weight-bold) !important;
  letter-spacing: 0.1px;
  line-height: 1;
  transition: all 500ms ease 0s !important;
}

#downloadButtonAdmin.downloadButton:hover {
  background-color: rgb(46, 187, 31);
  color: var(--argus-white);
}

.requestPaper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
}

.requestPaper .requestTitle {
  color: rgb(33, 131, 181);
  font-size: 18px;
  margin: 0px;
  padding: 0px;
  width: 273px;
  background-color: black;
  text-align: center;
}

.requestListItem {
  font-size: 18px;
  color: rgb(65, 65, 65);
}

#emailUserModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.375);
  z-index: 9999;
  max-height: calc(100vh - 160px);
  top: 160px;
}

#emailUserContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 850px;
  height: 550px;
  background-color: var(--main-blue);
  outline: 3px solid var(--outline-color);
  box-shadow: var(--small-container-box-shadow);
  border-radius: 2px;
}

#emailUserHeading {
  color: var(--main-header-color);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0 5px 0;
  padding: 5px 0 8px 0;
  width: 100%;
  height: 45px !important;
  border-bottom: 3px solid var(--outline-color);
  text-align: center;
  font-size: 30px;
  font-weight: var(--default-font-weight);
  font-family: var(--default-font);
}

#emailUserHr {
  border: none;
  border-bottom: 2px solid var(--outline-color);
  padding: 0;
  margin: 0;
  width: 100%;
}

#emailUserList {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 15px;
  width: 840px;
  height: 589px;
  max-height: 100%;
  overflow-y: scroll;
  padding: 10px 0 0 0 !important;
  margin: 10px 0 0 0 !important;
  box-sizing: content-box !important;
}

#emailUserListItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 5px 0;
  width: 250px;
  height: 150px;
  margin: 5px 0 !important;
  outline: 2px solid var(--outline-color);
  border-radius: 3px;
  background-color: var(--argus-blue-dark);
}

#userIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 165px;
  gap: 8px;
}

ol.emailList {
  list-style-type: none;
}

#userNameP {
  text-indent: 0px;
  font-size: 15px !important;
  font-weight: var(--default-font-weight) !important;
  font-family: var(--default-font) !important;
  color: var(--p-white) !important;
  padding: 0 !important;
}

#emailUserP {
  text-indent: 0px;
  font-size: 15px !important;
  font-weight: var(--default-font-weight) !important;
  font-family: var(--default-font) !important;
  color: var(--p-white) !important;
  margin: 10px 0 auto 0 !important;
  padding: 0 !important;
  letter-spacing: 0.3px;
}

.userIcon#userIcon {
  color: var(--argus-white);
}

#inviteUserButton.inviteUserButton {
  color: var(--btn-white-text) !important;
  background-color: var(--btn-green) !important;
  text-shadow: none !important;
  height: 24px;
  max-width: 50px !important;
  font-size: 12px;
  font-weight: var(--font-weight-bold);
  font-family: var(--default-font) !important;
  letter-spacing: 0.2px;
  transition: 550ms ease;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
  margin: 0 0 10px 0 !important;
  padding: 0 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  line-height: 1 !important;
}

#inviteUserButton.inviteUserButton:hover {
  background-color: #0dbf03;
  color: rgb(37, 37, 37);
  cursor: pointer;
}

ol.emailList > li.emailListItem {
  text-indent: -2rem;
  color: rgb(60, 60, 60) !important;
  width: max-content;
  height: fit-content;
  align-self: center;
  font: bold 18px var(--default-font);
}

/* - Search for user */
#emailUserSearch {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  margin: 20px 0 15px 0;
  padding: 0;
}

#search-user-form {
  background-color: var(--input-white-bg);
  box-shadow: var(--input-box-shadow);
  border-radius: 5px;
  border: none;
  outline: none;
  height: 40px;
  width: 400px;
  padding: 0;
}

#search-user-label {
  font-family: var(--default-font) !important;
  color: var(--input-label-color) !important;
  font-weight: 550;
  font-size: 18px;
  transform: translate(38px, 11px) scale(1);
}

#search-user-label.search-user-label-shrink {
  color: var(--shrink-label-color) !important;
  font-weight: var(--default-font-weight) !important;
  transform: translate(3px, -18px) scale(0.75) !important;
}

#search-user-helper-text {
  font-family: var(--default-font);
  color: rgb(255, 242, 0) !important;
  font-weight: var(--default-font-weight) !important;
  font-size: 13px;
  margin: 2px 0 0 0;
  padding: 0;
  text-align: center;
}

.search-user-field-root {
  display: flex;
  align-self: flex-start;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  border: none;
  outline: none;
  padding: 0 5px !important;
  margin: 0;
  min-height: 100% !important;
  width: 100% !important;
  background-color: transparent;
}

.search-user-field-input#search-user-field {
  color: var(--input-text-color);
  background-color: transparent;
  box-shadow: none;
  text-indent: 8px;
  padding: 0 !important;
  margin: 0 !important;
  font-weight: var(--default-font-weight);
  border-radius: 5px;
  z-index: 999;
  letter-spacing: 0.5px;
  font-family: var(--default-font);
  height: 100% !important;
}

.search-user-field-notchedOutline {
  border: none !important;
  outline: none !important;
}

#clear-search-btn {
  padding: 4px;
  border-radius: 50%;
}

#clear-search-btn:hover {
  background-color: rgba(255, 100, 100, 0.345);
  cursor: pointer;
}

.clear-search-icon {
  color: var(--close-icon-color);
  stroke: var(--close-icon-color);
}

.search-icon {
  color: rgba(90, 90, 90, 0.809);
  stroke: rgba(90, 90, 90, 0.809);
  padding: 4px;
  cursor: default;
}

#contactsModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.375);
  z-index: 9999;
  max-height: calc(100% - 160px);
  top: 160px;
}

#contactsContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 650px;
  height: 300px;
  background-color: var(--main-blue);
  outline: 3px solid var(--outline-color);
  border-radius: 2px;
}

#contactsHeading {
  color: var(--main-header-color);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 10px 0;
  padding: 0;
  width: 100%;
  height: 50px !important;
  border-bottom: 3px solid var(--outline-color);
  box-shadow: 0px 1px 2px 1px rgba(80, 80, 80, 0.241);
  text-align: center;
  font-size: 30px;
  font-weight: var(--default-font-weight) !important;
  font-family: var(--default-font) !important;
}

#contactsHr {
  border: none;
  border-bottom: 2px solid var(--outline-color);
  padding: 0;
  margin: 0;
  width: 100%;
}

.contacts {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 35px;
  width: inherit;
  height: fit-content;
  max-height: 100%;
  overflow-y: scroll;
  padding: 2px 0 !important;
  margin: 0 !important;
}

.contactsInfoContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
  align-items: center;
  width: 275px;
  height: 190px;
  max-height: 190px;
  gap: 5px;
  background-color: var(--argus-blue-dark);
  outline: 2px solid var(--outline-color);
  margin: 0 !important;
  padding: 0 !important;
}

.contactsSubHeading {
  color: var(--main-header-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid var(--outline-color);
  padding: 5px 0;
  margin: 0;
  background-color: transparent;
  width: 100%;
  height: 28px !important;
  text-align: center;
  font-size: 23px;
  font-weight: var(--default-font-weight);
  font-family: var(--default-font);
}

.contactsInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  width: inherit;
  max-height: 320px;
  overflow-y: scroll;
  padding: 0 !important;
  margin: 5px 0 !important;
  box-sizing: content-box !important;
}

.contactDetailsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: fit-content;
}

.contactDetailsIcon {
  color: var(--main-header-color);
  height: 17px;
  width: 17px;
  margin: 0 0 0 10px;
  padding: 0;
  filter: drop-shadow(0px 1px 1px #001f319d);
}

.contactDetails {
  font-size: 17px;
  color: var(--p-white);
  font-family: var(--default-font);
  font-weight: var(--default-font-weight);
  margin: 0;
  padding: 5px;
}

a.contactDetails {
  text-decoration: none;
}

a.contactDetails:hover {
  text-decoration: underline;
}

.footerContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin: 0;
  padding: 0;
}

.paginationChange {
  margin: 0;
  padding: 0;
}

.pagination {
  display: flex;
  margin: 0px;
  padding: 0px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-family: var(--default-font);
  width: 100%;
  max-width: 550px !important;
  overflow: visible;
}

#paginationRoot {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.paginationToolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  max-width: 525px;
  width: 100% !important;
  font-family: var(--default-font);
  margin: 0;
  padding: 0;
  overflow: visible;
  color: var(--argus-white);
}

.noRowsOverlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}

.noRowsOverlayText {
  color: var(--argus-white);
  font-size: 1.5em;
  font-weight: var(--default-font-weight);
  padding: 0px;
  margin-bottom: 15px 0px 20px 0px;
  align-self: center;
  width: fit-content;
}

.noRowsOverlayButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 45px;
  background-color: rgb(51, 213, 10);
  color: var(--argus-white);
  border-radius: 8px;
  margin-top: 10px;
  font-size: 16px;
  font-weight: var(--default-font-weight);
}

.noRowsOverlayButtonIcon {
  stroke: var(--argus-white);
  color: var(--argus-white);
  padding: 0px 5px 0px 0px;
}

.noRowsOverlayButton:hover {
  cursor: pointer;
  background-color: rgb(59, 217, 16);
}

.noRowsOverlayButtonText {
  width: 180px;
}

@media screen and (width < 1025px) {
  #editButtonAdmin.editButton {
    height: 22px;
    max-width: 48px !important;
    min-width: 48px !important;
    font-size: 9px !important;
  }
  #inProgressButtonAdmin.inProgressButton,
  #archiveButtonAdmin.archiveButton,
  #reviseButtonAdmin.reviseButton {
    height: 22px;
    max-width: 55px !important;
    min-width: 55px !important;
    font-size: 10px;
  }

  #pendingButtonAdmin.pendingButton {
    height: 22px;
    max-width: 55px !important;
    min-width: 55px !important;
    font-size: 10px;
  }

  #downloadButtonAdmin.downloadButton {
    height: 20px !important;
    width: 72px !important;
    font-size: 8px !important;
  }

  .fileDetailsButton#fileDetailsButton {
    width: 25px;
    height: 25px;
  }

  #fileDetailsIcon {
    height: 20px;
    width: 20px;
  }

  .contactsButtonRoot.contactsButton {
    width: 25px !important;
    height: 25px !important;
  }

  #contactsIcon {
    height: 20px !important;
    width: 20px !important;
  }

  .emailUserButtonRoot.emailUserButton {
    height: 25px !important;
    width: 25px !important;
  }

  .emailUserButtonRoot.emailUserButton #emailUserIcon {
    height: 20px;
    width: 20px;
  }
}

@media screen and (1025px <= width < 1450px) {
  #editButtonAdmin.editButton {
    height: 22px;
    max-width: 50px !important;
    min-width: 50px !important;
    font-size: 10px;
  }
  #inProgressButtonAdmin.inProgressButton,
  #archiveButtonAdmin.archiveButton,
  #reviseButtonAdmin.reviseButton {
    height: 22px;
    max-width: 60px !important;
    min-width: 60px !important;
    font-size: 10px;
  }

  #pendingButtonAdmin.pendingButton {
    height: 22px;
    max-width: 60px !important;
    min-width: 60px !important;
    font-size: 10px;
  }

  #downloadButtonAdmin.downloadButton {
    height: 22px;
    width: 80px !important;
    font-size: 10px;
  }

  .fileDetailsButton#fileDetailsButton {
    width: 30px;
    height: 30px;
  }

  #fileDetailsIcon {
    height: 25px;
    width: 25px;
  }

  .contactsButtonRoot.contactsButton {
    width: 30px !important;
    height: 30px !important;
  }

  #contactsIcon {
    height: 25px !important;
    width: 25px !important;
  }

  .emailUserButtonRoot.emailUserButton {
    height: 30px !important;
    width: 30px !important;
  }

  .emailUserButtonRoot.emailUserButton #emailUserIcon {
    height: 25px;
    width: 25px;
  }
}

@media screen and (1450px <= width < 1630px) {
  #pendingButtonAdmin.pendingButton {
    height: 25px;
    width: 65px !important;
    font-size: 11px;
  }

  #downloadButtonAdmin.downloadButton {
    height: 27px;
    width: 90px !important;
    font-size: 11px !important;
  }

  .fileDetailsButton#fileDetailsButton {
    width: 35px;
    height: 35px;
  }

  #fileDetailsIcon {
    height: 30px;
    width: 30px;
  }

  .contactsButtonRoot.contactsButton {
    width: 35px !important;
    height: 35px !important;
  }

  #contactsIcon {
    height: 28px !important;
    width: 28px !important;
  }

  .emailUserButtonRoot.emailUserButton {
    height: 35px !important;
    width: 35px !important;
  }

  .emailUserButtonRoot.emailUserButton #emailUserIcon {
    height: 28px;
    width: 28px;
  }
}

@media screen and (1630px <= width < 1850px) {
  #editButtonAdmin.editButton,
  #inProgressButtonAdmin.inProgressButton,
  #archiveButtonAdmin.archiveButton,
  #reviseButtonAdmin.reviseButton {
    height: 25px !important;
    max-width: 60px !important;
    min-width: 60px !important;
    font-size: 11px !important;
  }

  #pendingButtonAdmin.pendingButton {
    height: 25px;
    width: 60px !important;
    font-size: 11px;
  }

  #downloadButtonAdmin.downloadButton {
    height: 25px;
    width: 90px !important;
    font-size: 11px !important;
  }

  .fileDetailsButton#fileDetailsButton {
    width: 35px;
    height: 35px;
  }

  #fileDetailsIcon {
    height: 30px;
    width: 30px;
  }

  .contactsButtonRoot.contactsButton {
    width: 35px !important;
    height: 35px !important;
  }

  #contactsIcon {
    height: 28px !important;
    width: 28px !important;
  }

  .emailUserButtonRoot.emailUserButton {
    height: 35px !important;
    width: 35px !important;
  }

  .emailUserButtonRoot.emailUserButton #emailUserIcon {
    height: 28px;
    width: 28px;
  }
}

@media screen and (1850px <= width) {
  #editButtonAdmin.editButton,
  #inProgressButtonAdmin.inProgressButton,
  #archiveButtonAdmin.archiveButton,
  #reviseButtonAdmin.reviseButton {
    height: 25px !important;
    max-width: 55px !important;
    min-width: 55px !important;
    font-size: 11px !important;
  }

  #pendingButtonAdmin.pendingButton {
    height: 25px;
    min-width: 55px !important;
    max-width: 60px !important;
    font-size: 11px;
  }

  #downloadButtonAdmin.downloadButton {
    height: 25px;
    width: 90px !important;
    font-size: 11px !important;
  }

  .fileDetailsButton#fileDetailsButton {
    width: 35px;
    height: 35px;
  }

  #fileDetailsIcon {
    height: 30px;
    width: 30px;
  }

  .contactsButtonRoot.contactsButton {
    width: 35px !important;
    height: 35px !important;
  }

  #contactsIcon {
    height: 30px !important;
    width: 30px !important;
  }

  .emailUserButtonRoot.emailUserButton {
    height: 35px !important;
    width: 35px !important;
  }

  .emailUserButtonRoot.emailUserButton #emailUserIcon {
    height: 30px;
    width: 30px;
  }
}
