#attach-files-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center !important;
  justify-self: center;
  gap: 10px;
  margin: 0;
  padding: 10px;
  background-color: #316d83;
  border-radius: 8px;
  max-height: 615px;
  height: 100%;
  min-height: 610px;
  max-width: 500px;
  min-width: 500px;
  /* position: relative; */
  /* top: 45px; */
}

.drag-files-box#drag-files-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  outline: 3px dashed var(--outline-color);
  text-align: center;
  cursor: pointer;
  gap: 40px;
  margin: 0;
  padding: 2px 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  flex-grow: 3;
}

.upload-file-icon#upload-icon {
  font-size: 80px;
  color: white;
}

.drag-files-text#drag-files-text {
  font-size: 23px;
  font-family: var(--default-font);
  font-weight: var(--font-weight-bold);
  color: var(--argus-white);
}

#attached-files {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-grow: 1;
  margin: 5px 0;
  padding: 0 5px;
  gap: 10px;
  width: 100%;
}

#attached-files.hide-attached {
  display: none;
}

.attached-file {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  gap: 8px;
}

.file-name#file-name {
  font-size: 14px;
  padding: 0;
  margin: 0;
  vertical-align: middle;
  font-family: var(--default-font);
  font-weight: var(--default-font-weight);
  color: var(--argus-white);
  line-height: 1;
}

.delete-attached-icon#delete-attached-icon {
  padding: 0;
  margin: 0;
  height: 13px;
  width: 13px;
  fill: rgb(236, 17, 17);
  cursor: pointer;
  stroke: rgb(236, 17, 17);
  line-height: 1;
}
