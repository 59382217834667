#admin-grid-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: clamp(1020px, 95vw, 1875px) !important;
  min-width: 1020px;
  max-width: 1875px;
  height: auto !important;
  max-height: calc(100vh - 280px) !important;
  box-shadow: none !important;
  border-radius: 5px;
  padding: 15px 0;
  pointer-events: all;
}

#admin-grid-container.no-rows {
  min-height: 450px !important;
}

.MuiDataGridPro-container--top {
  background-color: var(--argus-blue-dark) !important;
}

#grid-header {
  position: relative;
  align-self: flex-start;
  font-size: clamp(1.2em, 2vw, 1.8em);
  width: fit-content;
  text-shadow: -1px 1px 2px rgba(1, 19, 31, 0.215);
  color: var(--argus-black);
  margin: 0 0 10px 0 !important;
  font-family: var(--default-font) !important;
  font-weight: var(--font-weight-bold);
}

.MuiDataGrid-pinnedColumns .MuiDataGrid-row.Mui-hovered,
.MuiDataGrid-row.Mui-hovered {
  background-color: initial !important;
  opacity: 1;
}

.MuiInputBase-root .MuiSelect-root::before {
  border-bottom: none !important;
}

.MuiDataGrid-row--editing {
  box-shadow: none !important;
}

/*! TOOLBAR */
.mainToolbarContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: auto;
  padding: 4px 0;
  margin: 0;
  width: 100%;
  background-color: var(--app-bar-bg);
}

.toolbarLeft#toolbar-left {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  height: fit-content;
  max-height: 50px;
  width: fit-content;
  margin: 0 0 0 3px;
  padding: 0;
}

.toolbarLeft#toolbar-left .MuiButton-root {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--label-color) !important;
  font-family: var(--default-font) !important;
  font-weight: var(--default-font-weight) !important;
  font-size: 1.2em;
  margin: 0 !important;
  line-height: 1;
  height: fit-content;
  text-shadow: none;
  opacity: 1;
  background-color: transparent;
}

.toolbarLeft#toolbar-left .toolbarFilter.toolbar-filter-button:hover {
  background-color: transparent !important;
  cursor: pointer;
}

.toolbarFilter.toolbar-filter-button .css-y6rp3m-MuiButton-startIcon {
  display: flex;
  margin: 0 !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
  padding: 0 !important;
  stroke-width: 0.45px;
  font-size: 1.2em;
}

.toolbarRight.adminToolbarRight {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-self: flex-end;
  align-items: center;
  height: fit-content;
  width: fit-content;
  gap: 25px;
}

.switch-formControlLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: flex-start;
  max-width: fit-content;
  gap: 15px;
  max-height: fit-content;
  line-height: 1;
  padding: 0 !important;
  margin: 0 !important;
  font-family: var(--default-font) !important;
  font-weight: var(--default-font-weight) !important;
  color: var(--label-color) !important;
}

.admin-archive-switch.archive-switch-root {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: auto;
  padding: 0;
  overflow: visible;
}

.admin-archive-switch.archive-switch-root .archive-switchBase {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  transition-duration: 325ms;
  transform: translate(0, 0);
}

.admin-archive-switch.archive-switch-root
  .archive-switchBase
  .archive-switch-base-input {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-self: center;
  position: absolute;
  left: 0;
  margin: 0;
  padding: 0;
  opacity: 1;
}

.admin-archive-switch .archive-switchBase .archive-switch-thumb {
  position: absolute;
  left: 0;
  top: 0;
  box-sizing: border-box;
  height: 17px;
  width: 17px;
  background-color: var(--label-color);
}

.admin-archive-switch .archive-switch-track {
  border-radius: 10px;
  background-color: #a8a8a8;
  width: 34px;
  opacity: 1;
  transition: background-color 320ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  height: 17px;
}

.admin-archive-switch.archive-switch-root
  .archive-switchBase.archive-switch-checked {
  transform: translate(16.5px, 0);
  color: var(--label-color);
}

.admin-archive-switch.archive-switch-root
  .archive-switchBase.archive-switch-checked
  + .archive-switch-track {
  background-color: #52df3f;
  opacity: 1;
  border: 0;
}

.admin-archive-switch.archive-switch-root
  .archive-switchBase.archive-switch-checked
  .Mui-disabled
  + .archive-switch-track {
  opacity: 0.5;
}

.switch-form-label {
  color: var(--label-color);
  font-weight: var(--font-weight-bold) !important;
  font-family: var(--default-font) !important;
}

#search-form-control {
  background-color: var(--input-white-bg);
  border-radius: 5px;
  border: none;
  outline: none;
  height: 40px;
  margin: 0 5px 0 0 !important;
  padding: 0;
}

.search-field-root {
  display: flex;
  align-self: flex-start;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  border: none;
  outline: none;
  padding: 0 5px !important;
  margin: 0;
  height: 100% !important;
  width: 100% !important;
  background-color: transparent;
}

.search-field-input#search-field {
  color: var(--input-text-color);
  background-color: transparent;
  box-shadow: none;
  text-indent: 8px;
  padding: 0 !important;
  margin: 0 !important;
  font-weight: var(--default-font-weight);
  border-radius: 5px;
  z-index: 999;
  letter-spacing: 0.7px;
  font-family: var(--default-font);
  height: inherit;
}

.search-field-notchedOutline {
  border: none !important;
  outline: none !important;
}

#clear-search-btn {
  padding: 4px;
  border-radius: 50%;
}

#clear-search-btn:hover {
  background-color: rgba(255, 100, 100, 0.345);
  cursor: pointer;
}

.clear-search-icon {
  color: var(--close-icon-color);
  stroke: var(--close-icon-color);
}

.search-icon {
  color: rgba(90, 90, 90, 0.809);
  stroke: rgba(90, 90, 90, 0.809);
  padding: 4px;
  cursor: default;
}

@media screen and (height < 800px) {
  #admin-grid-container {
    max-height: 550px;
    min-height: fit-content;
  }
}

@media screen and (width < 1025px) {
  #admin-grid-container {
    min-width: 100%;
  }

  .mainToolbarContainer,
  .toolbarLeft,
  .toolbarRight {
    max-height: 40px;
    min-height: 40px;
  }

  #toolbar-column-button,
  .toolbarFilter {
    font-size: 11px !important;
  }

  .toolbarFilter .MuiButton-icon .MuiSvgIcon-root {
    height: 20px;
  }

  #search-form-control {
    max-width: 400px !important;
    min-width: 350px !important;
    max-height: 30px !important;
  }

  .search-field-root {
    height: 100%;
    width: 100%;
  }

  .search-icon,
  .clear-search-icon {
    max-height: 17px !important;
    max-width: 17px !important;
  }

  #search-field.search-field-input {
    height: inherit;
    font-size: 12px !important;
  }

  .toolbarRight {
    max-width: 520px;
    width: fit-content !important;
    gap: 15px !important;
  }

  #switch-form-control-label {
    max-width: 270px !important;
    width: fit-content !important;
  }

  .switch-form-label {
    font-size: 11px !important;
    font-weight: var(--default-font-weight) !important;
  }

  .admin-archive-switch .archive-switchBase .archive-switch-thumb {
    height: 13px;
    width: 13px;
  }

  .admin-archive-switch .archive-switch-track {
    border-radius: 10px;
    background-color: #a8a8a8;
    width: 26px;
    opacity: 1;
    transition: background-color 320ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    height: 13px;
  }
}

@media screen and (1025px <= width < 1450px) {
  #admin-grid-container {
    min-width: 1250px;
  }

  .mainToolbarContainer,
  .toolbarLeft,
  .toolbarRight {
    max-height: 42px;
    min-height: 42px;
  }

  #toolbar-column-button,
  .toolbarFilter {
    font-size: 14px !important;
  }

  .toolbarFilter .MuiButton-icon .MuiSvgIcon-root {
    height: 22px;
  }

  #search-form-control {
    max-width: 450px;
    max-height: 32px;
  }

  .search-field-root {
    height: 100%;
    width: 100%;
  }

  .search-icon,
  .clear-search-icon {
    max-height: 20px;
    max-width: 20px;
  }

  #search-field.search-field-input {
    height: inherit;
    font-size: 14px;
  }

  .toolbarRight {
    max-width: 710px;
  }

  #switch-form-control-label {
    max-width: 235px;
  }

  .switch-form-label {
    font-size: 14px !important;
  }
}

@media screen and (1450px <= width < 1630px) {
  #admin-grid-container {
    width: 90%;
  }

  .mainToolbarContainer,
  .toolbarLeft,
  .toolbarRight {
    max-height: 35px;
    min-height: 35px;
  }

  #toolbar-column-button,
  .toolbarFilter {
    font-size: 15px !important;
  }

  .toolbarFilter .MuiButton-icon .MuiSvgIcon-root {
    height: 23px;
  }

  #search-form-control {
    max-width: 450px;
    max-height: 35px;
  }

  .search-field-root {
    height: 100%;
    width: 100%;
  }

  .search-icon,
  .clear-search-icon {
    max-height: 22px;
    max-width: 22px;
  }

  #search-field.search-field-input {
    height: inherit;
    font-size: 15px;
  }

  .toolbarRight {
    max-width: 725px;
  }

  #switch-form-control-label {
    max-width: 245px;
  }

  .switch-form-label {
    font-size: 15px !important;
  }
}

@media screen and (1630px <= width < 1850px) {
  #admin-grid-container {
    width: 90%;
  }

  .mainToolbarContainer {
    max-height: 40px;
    min-height: 40px;
  }
  .toolbarRight {
    width: 810px;
  }

  #search-form-control {
    width: 525px;
  }

  .search-field-root {
    height: 100%;
    width: 100%;
  }

  #search-field.search-field-input {
    height: inherit;
    font-size: 18px;
  }

  #switch-form-control-label {
    width: 255px;
  }

  .switch-form-label {
    font-size: 16px;
  }
}

@media screen and (width >= 1850px) {
  .toolbarRight {
    width: 855px;
  }

  #search-form-control {
    width: 575px;
    height: 40px;
  }

  .search-field-root {
    height: 100%;
    width: 100%;
  }

  #search-field.search-field-input {
    height: inherit;
    font-size: 18px;
  }

  #switch-form-control-label {
    width: 255px;
  }
}

@media screen and (1850px <= width < 2000px) {
  #admin-grid-container {
    width: 90%;
  }
}

@media screen and (2000px <= width < 2150px) {
  #admin-grid-container {
    max-width: 1850px;
    min-width: 1800px;
  }
}

@media screen and (width >= 2150px) {
  #admin-grid-container {
    max-width: 2050px;
    min-width: 1900px;
  }
}
