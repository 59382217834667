.email-client-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  align-self: center;
  width: 100%;
  min-width: 100vw;
  height: calc(100vh - 160px);
  position: relative;
  top: 55px;
  gap: 50px;
  overflow: visible;
}

#email-client {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  top: 40px;
  width: 905px;
  min-height: calc(100vh - 350px);
  height: auto;
  background-color: var(--main-blue);
  padding: 0;
  outline: 4px solid var(--outline-color);
  box-shadow: var(--input-container-box-shadow);
  border-radius: 2px;
  transition: height 0.3s ease-in-out 0s;
}

#email-client.no-client {
  max-height: 300px;
  min-height: 250px;
}

#user-header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  border-bottom: 4px solid var(--outline-color);
}

#email-client-heading {
  font-size: 26px;
  text-shadow: 0px 1px 2px rgb(53, 53, 53);
  width: 100%;
  text-align: center;
  font-family: var(--default-font);
  font-weight: var(--font-weight-bold);
  color: var(--main-header-color);
  text-shadow: var(--header-text-shadow);
}

#send-email-heading {
  font-size: 26px;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.335);
  width: 100%;
  text-align: center;
  font-family: var(--default-font);
  font-weight: var(--default-font-weight);
  color: var(--main-header-color);
  text-shadow: var(--header-text-shadow);
}

.create-user-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 2;
  width: 100%;
  gap: 22px;
}

#send-email-p {
  color: var(--p-white);
  font-size: 20px;
  font-family: var(--default-font);
  width: 95%;
  text-align: center;
  font-weight: var(--default-font-weight);
}

#send-email-email {
  color: rgb(0, 0, 235);
  text-decoration: underline;
}

/* | CLIENT SELECT | */
.client-input-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 750px;
  font-family: var(--default-font) !important;
  height: 55px !important;
  margin: 5px 0;
}

#clients-form {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 425px;
  height: 35px !important;
  background-color: var(--input-white-bg);
  border-radius: 5px;
  box-shadow: var(--input-box-shadow);
  border: none;
}

.client-select-label {
  font-size: 20px;
  font-weight: var(--default-font-weight);
  font-family: var(--default-font) !important;
  color: var(--label-color) !important;
  text-shadow: var(--form-label-text-shadow) !important;
  margin-right: auto;
  pointer-events: none !important;
  user-select: none !important;
}

#clients-select::before {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.clients-select-root::before,
.clients-select-root::after {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.clients-select-root {
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 !important;
  padding: 0 !important;
  border-radius: 5px;
  border: none;
  outline: none;
  background-color: transparent !important;
}

.clients-select-root:focus,
.clients-select-root:focus-within {
  border: none !important;
  outline: none !important;
  background-color: transparent !important;
}

#clients-select {
  display: flex !important;
  justify-self: center !important;
  align-self: center !important;
  align-items: center !important;
  text-indent: 8px;
  margin: 0 !important;
  padding: 0 !important;
  height: 100% !important;
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
  font-family: var(--default-font) !important;
  font-size: 16px;
  color: var(--input-text-color) !important;
  font-weight: var(--default-font-weight);
  background-color: transparent !important;
}

#clients-select:focus,
#clients-select:focus-within {
  outline: none !important;
  border: none !important;
  background-color: transparent !important;
}

.clients-select-nativeInput,
.clients-select-nativeInput:focus,
.clients-select-nativeInput:focus-within {
  border: none !important;
  outline: none !important;
}

#client-item.clients-select-menu-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: none;
  box-shadow: none;
  padding: 10px 10px;
  font-size: 18px !important;
  font-weight: var(--default-font-weight) !important;
  color: var(--p-white) !important;
  font-family: var(--default-font) !important;
}

#clear-client-item.clients-select-menu-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: none;
  box-shadow: none;
  padding: 10px 10px;
  font-size: 18px !important;
  font-weight: var(--default-font-weight) !important;
  color: rgba(221, 221, 221, 0.341) !important;
  font-family: var(--default-font) !important;
}

.clients-select-root-icon {
  border-radius: 50%;
  margin-right: 5px;
  color: rgb(94, 94, 94) !important;
}

.clients-select-paper.MuiPaper-root {
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
  align-items: center !important;
  background-color: var(--main-blue) !important;
  outline: 2px solid var(--outline-color) !important;
  margin-top: 3px !important;
  max-height: 200px !important;
  overflow-y: scroll !important;
}

.clients-select-list {
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;
  height: 100%;
  max-height: fit-content;
}

.clients-list-item-divider,
.clear-client-item-divider {
  border-bottom: 2px solid var(--outline-color) !important;
  width: 100% !important;
}

#clear-client-icon {
  color: var(--close-icon-color) !important;
  margin: 0 0 0 10px !important;
  stroke: var(--close-icon-color) !important;
}

#client-item.clients-list-item {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  min-width: 100% !important;
  text-align: center !important;
  color: var(--p-white) !important;
  font-weight: var(--default-font-weight) !important;
  font-size: 18px !important;
  padding: 10px 10px !important;
  font-family: var(--default-font) !important;
  background-color: transparent !important;
}

#clear-client-item.clients-list-item {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  min-width: 100% !important;
  text-align: center !important;
  color: var(--p-white) !important;
  font-weight: var(--default-font-weight) !important;
  font-size: 18px !important;
  padding: 10px 10px !important;
  font-family: var(--default-font) !important;
  background-color: rgba(40, 40, 40, 0.845) !important;
}

#clear-client-item.clients-list-item:hover {
  background-color: var(--argus-blue-darker) !important;
  color: var(--p-white) !important;
}

#client-item.clients-list-item:hover {
  background-color: var(--argus-blue-darker) !important;
  color: white !important;
}

#client-item.clients-list-item-selected {
  background-color: var(--argus-blue-dark) !important;
  color: white !important;
}

/* | Create User Form */

.send-email-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
}

.user-input-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 750px;
  font-family: var(--default-font) !important;
  margin: 5px 0;
  height: 55px !important;
}

.create-user-label {
  font-size: 20px;
  font-weight: var(--default-font-weight);
  font-family: var(--default-font) !important;
  color: var(--label-color) !important;
  text-shadow: var(--form-label-text-shadow) !important;
  margin-right: auto;
  pointer-events: none !important;
  user-select: none !important;
}

#new-user-name-form,
#new-user-email-form,
#new-user-phone-form,
#new-user-client-form,
#new-user-title-form {
  box-shadow: var(--input-box-shadow);
  background-color: var(--input-white-bg);
  border-radius: 5px;
  border: none;
  outline: none;
  height: 35px;
  width: 425px;
}

.new-user-name-root,
.new-user-email-root,
.new-user-phone-root,
.new-user-client-root,
.new-user-title-root {
  display: flex;
  align-self: flex-start;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  border: none;
  outline: none;
  padding: 0 !important;
  margin: 0 !important;
  height: 100%;
  width: 100%;
  border: none !important;
  outline: none !important;
}

.new-user-form-root {
  outline: none !important;
}

.new-user-form-root.required {
  outline: 2px solid var(--input-outline-required) !important;
}

.user-notchedOutline,
.user-notchedOutline:focus-within {
  border: none !important;
  outline: none !important;
}

.new-user-name-input#new-user-name,
.new-user-email-input#new-user-email,
.new-user-phone-input#new-user-phone,
.new-user-client-input#new-user-client,
.new-user-title-input#new-user-title {
  color: var(--input-text-color) !important;
  font-size: 16px;
  box-shadow: none;
  text-indent: 8px;
  padding: 0 !important;
  margin: 0 !important;
  font-weight: var(--default-font-weight);
  border-radius: 5px;
  z-index: 999;
  letter-spacing: 0.7px;
  font-family: var(--default-font);
  height: inherit;
  background-color: transparent;
}

.predict-title-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

#title-placeholder {
  font-family: var(--default-font);
  position: absolute;
  text-shadow: none;
  text-indent: 8px;
  font-weight: var(--default-font-weight);
  box-shadow: none;
  font-size: 18px;
  letter-spacing: 0.7px;
  padding: 0 !important;
  color: var(--input-place-holder-color) !important;
  border: none;
  border-radius: 5px;
  outline: none;
  pointer-events: none;
  background-color: transparent;
  min-height: 40px;
  width: 270px;
}

#new-user-name-label,
#new-user-email-label,
#new-user-phone-label,
#clients-select-label,
#new-user-title-label {
  font-family: var(--default-font) !important;
  color: var(--input-label-color) !important;
  font-weight: var(--default-font-weight) !important;
  transform: translate(12px, 7px) scale(1) !important;
}

#new-user-name-label.user-label-shrink,
#new-user-email-label.user-label-shrink,
#new-user-phone-label.user-label-shrink,
#clients-select-label.labelShrink,
#new-user-title-label.user-label-shrink {
  transform: translate(3px, -16px) scale(0.72) !important;
  color: var(--shrink-label-color) !important;
}

.user-field-clear-adornment#text-adornment {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px 0 0 !important;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

#clear-icon-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 10px;
  pointer-events: auto;
}

.user-field-clear-adornment#text-adornment:hover {
  cursor: pointer;
  background-color: rgba(255, 88, 88, 0.276);
}

.close-icon {
  font-size: 22px !important;
  color: var(--close-icon-color);
  stroke: var(--close-icon-color);
  margin: 0;
  padding: 0;
}

#create-user-btn.create-user-btn,
#send-email-btn.send-email-btn {
  color: var(--btn-white-text);
  background-color: var(--btn-green);
  font-family: var(--default-font) !important;
  font-size: 14px;
  font-weight: var(--font-weight-bold) !important;
  margin: 0 0 10px 0;
  height: 38px;
  line-height: 1;
  width: 190px;
  letter-spacing: 0.2px;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(3, 8, 15, 0.09) 0px 1px 1px,
    rgba(2, 1, 15, 0.09) 0px 2px 3px, rgba(0, 0, 0, 0.09) 0px 4px 4px;
  transition: all 350ms ease-in-out;
  pointer-events: auto;
}

#create-user-btn.create-user-btn:disabled {
  background-color: var(--disabled-btn-bg);
  color: var(--disabled-btn-color);
  box-shadow: none;
}

#create-user-btn.create-user-btn:disabled:hover {
  background-color: var(--disabled-btn-bg);
  color: var(--disabled-btn-color);
  cursor: not-allowed !important;
}

#create-user-btn.create-user-btn:hover,
#send-email-btn.send-email-btn:hover {
  background-color: var(--btn-green-hover);
  cursor: pointer;
}

.confirm-delete-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: calc(100vh - 160px);
  min-height: calc(100vh - 160px);
  background-color: rgba(64, 64, 64, 0.371);
  position: fixed !important;
  top: 161px !important;
}

.confirm-delete-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--main-blue);
  outline: 2px solid var(--outline-color);
  gap: 20px;
  border-radius: 3px;
  max-width: 750px;
  min-width: 650px;
  min-height: 150px;
  max-height: 250px;
}

#confirm-delete-heading {
  font-size: 24px;
  font-family: var(--default-font);
  font-weight: var(--font-weight-bold);
  color: var(--main-header-color);
  text-shadow: var(--header-text-shadow);
  margin: 0;
  padding: 0;
}

.confirm-delete-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.confirm-delete-btn#confirm-delete-btn {
  background-color: var(--btn-green) !important;
  color: var(--btn-white-text) !important;
  font-family: var(--default-font) !important;
  font-weight: var(--font-weight-bold) !important;
  line-height: 1;
  font-size: 14px;
  padding: 0;
  height: 28px;
  width: 84px;
}

.confirm-delete-btn#confirm-delete-btn:hover {
  background-color: var(--btn-green-hover);
}

.cancel-delete-btn#cancel-delete-btn {
  background-color: var(--btn-red) !important;
  color: var(--btn-white-text) !important;
  font-family: var(--default-font) !important;
  font-weight: var(--font-weight-bold) !important;
  line-height: 1;
  font-size: 14px;
  padding: 0;
  height: 28px;
  width: 80px;
}

.cancel-delete-btn#cancel-delete-btn:hover {
  background-color: var(--btn-red-hover);
}

@media screen and (width < 1450px) {
  #email-client {
    width: 750px;
    height: auto;
  }

  #user-header {
    height: 46px;
  }

  #email-client-heading {
    font-size: 22px;
  }

  .create-user-container {
    width: inherit;
    gap: 22px;
  }

  .client-input-container,
  .user-input-container {
    width: 90%;
    height: 60px !important;
    padding: 4px 0;
  }

  .create-user-label {
    font-size: 20px;
  }

  #clients-form {
    width: 385px;
    height: 36px !important;
  }

  #clients-select {
    font-size: 16px;
  }

  #new-user-name-form,
  #new-user-email-form,
  #new-user-phone-form,
  #new-user-client-form,
  #new-user-title-form {
    width: 385px;
    height: 36px;
  }

  #clients-select-label,
  #new-user-name-label,
  #new-user-email-label,
  #new-user-phone-label,
  #new-user-title-label {
    transform: translate(12px, 8px) scale(0.9) !important;
  }

  #create-user-btn.create-user-btn,
  #send-email-btn.send-email-btn {
    width: 178px;
    height: 32px;
    font-size: 13px;
  }

  .invite-modal-container {
    scale: 0.88;
  }
}

@media screen and (1450px <= width < 1750px) {
  #email-client {
    width: 830px;
    height: auto;
  }

  #user-header {
    height: 46px;
  }

  #email-client-heading {
    font-size: 24px;
  }

  .create-user-container {
    width: inherit;
  }

  .user-input-container {
    width: 90%;
  }

  .create-user-label {
    font-size: 21px;
  }

  #clients-form {
    width: 400px;
    height: 35px;
  }

  #clients-select {
    font-size: 16px;
  }

  #new-user-name-form,
  #new-user-email-form,
  #new-user-phone-form,
  #new-user-client-form,
  #new-user-title-form {
    width: 400px;
    height: 35px;
  }

  #clients-select-label {
    transform: translate(12px, 8px) scale(1) !important;
  }

  #new-user-name-label,
  #new-user-email-label,
  #new-user-phone-label,
  #clients-select-label,
  #new-user-title-label {
    transform: translate(12px, 8px) scale(1);
  }

  #create-user-btn.create-user-btn,
  #send-email-btn.send-email-btn {
    width: 190px;
    height: 36px;
    font-size: 14px;
  }

  .invite-modal-container {
    background-color: rgba(255, 94, 94, 0.371);
    scale: 0.92;
  }
}
