#main-pdf-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 925px;
  height: max-content;
  margin: 15px 0;
}

#pdf-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  align-self: center;
  width: 100%;
  height: fit-content;
}

#pdf-header {
  font-size: 30px;
  color: rgb(236, 236, 236);
  margin: 10px 0 0 0 !important;
  padding: 0 !important;
  font-family: var(--default-font);
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
}

#pdf-hr {
  width: 100%;
  color: rgb(244, 244, 244) !important;
  background-color: rgb(244, 244, 244) !important;
  border-top: 3px solid rgb(244, 244, 244);
  box-shadow: none;
  margin: 10px 0 0 0;
  padding: 0;
}

.questionnaire {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  min-height: 100% !important;
}

.page {
  box-shadow: 0px 3px 5px 3px rgba(0, 0, 0, 0.354);
}

#pdf-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 450px;
  min-width: 800px;
  width: inherit;
}

#pdf-loading-p {
  font-size: 24px;
  color: rgb(236, 236, 236);
  font-family: var(--default-font);
  font-weight: var(--font-weight-bold);
  margin: 0;
  padding: 0;
}

#pdf-value-overlay {
  position: absolute;
  width: 860px;
  height: 1110px;
  margin-top: 0;
  pointer-events: none;
  z-index: 999;
}

#invalid-overlay {
  outline: 2px dashed #d50303 !important;
  background-color: #ff000048 !important;
}

#valid-overlay {
  outline: 2px dashed #00d026 !important;
  background-color: #00ff2f50 !important;
}

.startDate-hover {
  position: absolute;
  top: 195px;
  left: 350px;
  width: 120px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.endDate-hover {
  position: absolute;
  top: 195px;
  left: 607px;
  width: 93px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.projectName-hover {
  position: absolute;
  top: 228px;
  left: 37px;
  width: 92px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.jobNumber-hover {
  position: absolute;
  top: 260px;
  left: 37px;
  width: 65px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.accountingEmail-hover {
  position: absolute;
  top: 260px;
  left: 312px;
  width: 181px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.submittingToRegAgency-hover {
  position: absolute;
  top: 289px;
  left: 37px;
  width: 367px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.regAgencySubmitDate-hover {
  position: absolute;
  top: 289px;
  left: 556px;
  width: 103px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.sNum-hover {
  position: absolute;
  top: 412px;
  left: 39px;
  width: 252px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.activeMyDeq-hover {
  position: absolute;
  top: 442px;
  left: 39px;
  width: 241px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.manualLogsRequest-hover {
  position: absolute;
  top: 442px;
  left: 475px;
  width: 180px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.noiRequest-hover {
  position: absolute;
  top: 476px;
  left: 39px;
  width: 250px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.dustAuditRequest-hover {
  position: absolute;
  top: 476px;
  left: 475px;
  width: 196px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.inspectionRequest-hover {
  position: absolute;
  top: 510px;
  left: 39px;
  width: 205px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.dustSignRequest-hover {
  position: absolute;
  top: 510px;
  left: 475px;
  width: 120px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.swpppSignRequest-hover {
  position: absolute;
  top: 544px;
  left: 39px;
  width: 259px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.oName-hover {
  position: absolute;
  top: 668px;
  left: 37px;
  width: 207px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.oPhone-hover {
  position: absolute;
  top: 668px;
  left: 613px;
  width: 50px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.oAdd-hover {
  position: absolute;
  top: 700px;
  left: 37px;
  width: 59px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.oCity-hover {
  position: absolute;
  top: 700px;
  left: 439px;
  width: 34px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.oZip-hover {
  position: absolute;
  top: 700px;
  left: 687px;
  width: 30px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.oContactName-hover {
  position: absolute;
  top: 731px;
  left: 37px;
  width: 190px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.oContactTitle-hover {
  position: absolute;
  top: 731px;
  left: 482px;
  width: 38px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.oContactPhone-hover {
  position: absolute;
  top: 776px;
  left: 37px;
  width: 48.5px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.oContactEmail-hover {
  position: absolute;
  top: 776px;
  left: 379px;
  width: 96px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.odrCompany-hover {
  position: absolute;
  top: 857px;
  left: 37px;
  width: 236.5px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.odrName-hover {
  position: absolute;
  top: 896px;
  left: 37px;
  width: 126.5px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.odrTitle-hover {
  position: absolute;
  top: 896px;
  left: 461px;
  width: 36px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.odrPhone-hover {
  position: absolute;
  top: 948px;
  left: 37px;
  width: 47.5px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.odrEmail-hover {
  position: absolute;
  top: 948px;
  left: 368px;
  width: 96.5px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.gcName-hover {
  position: absolute;
  top: 82px;
  left: 45px;
  width: 111.5px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.gcAdd-hover {
  position: absolute;
  top: 109px;
  left: 45px;
  width: 59.5px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.gcCity-hover {
  position: absolute;
  top: 109px;
  left: 424px;
  width: 35px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.gcZip-hover {
  position: absolute;
  top: 109px;
  left: 675px;
  width: 31px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.gcPhone-hover {
  position: absolute;
  top: 137px;
  left: 45px;
  width: 91px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.gcContactName-hover {
  position: absolute;
  top: 165px;
  left: 45px;
  width: 217px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.gcContactTitle-hover {
  position: absolute;
  top: 165px;
  left: 487px;
  width: 38px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.gcContactPhone-hover {
  position: absolute;
  top: 207px;
  left: 45px;
  width: 50px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.gcContactEmail-hover {
  position: absolute;
  top: 207px;
  left: 376px;
  width: 99px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.gcContact2Name-hover {
  position: absolute;
  top: 246px;
  left: 45px;
  width: 49px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.gcContact2Title-hover {
  position: absolute;
  top: 246px;
  left: 487px;
  width: 38px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.gcContact2Phone-hover {
  position: absolute;
  top: 288px;
  left: 45px;
  width: 34px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.gcContact2Email-hover {
  position: absolute;
  top: 288px;
  left: 377px;
  width: 99.5px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.gcContact3Name-hover {
  position: absolute;
  top: 323px;
  left: 45px;
  width: 49px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.gcContact3Title-hover {
  position: absolute;
  top: 323px;
  left: 487px;
  width: 38px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.gcContact3Phone-hover {
  position: absolute;
  top: 365px;
  left: 45px;
  width: 34px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.gcContact3Email-hover {
  position: absolute;
  top: 365px;
  left: 376px;
  width: 99.5px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.pAdd-hover {
  position: absolute;
  top: 462px;
  left: 44px;
  width: 148px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.totAcr-hover {
  position: absolute;
  top: 497.5px;
  left: 45px;
  width: 162.5px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.distAcr-hover {
  position: absolute;
  top: 497.5px;
  left: 436.5px;
  width: 152px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.scopeOfWork-hover {
  position: absolute;
  top: 538px;
  left: 45px;
  width: 102px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.emergencyContactName-hover {
  position: absolute;
  top: 623px;
  left: 45px;
  width: 229px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.emergencyContactTitle-hover {
  position: absolute;
  top: 623px;
  left: 533px;
  width: 39px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.emergencyContactPhone-hover {
  position: absolute;
  top: 657px;
  left: 45px;
  width: 33px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.emergencyContactEmail-hover {
  position: absolute;
  top: 657px;
  left: 368px;
  width: 99px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.laydownYard-hover {
  position: absolute;
  top: 695.5px;
  left: 45px;
  width: 425px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.laydownYardDist-hover {
  position: absolute;
  top: 723px;
  left: 62px;
  width: 436px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.borrowArea-hover {
  position: absolute;
  top: 752px;
  left: 45px;
  width: 470px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.portableTanksOnsite-hover {
  position: absolute;
  top: 791.5px;
  left: 45px;
  width: 309px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.portableTankCapacity-hover {
  position: absolute;
  top: 791.5px;
  left: 524px;
  width: 62px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.bmpCompanyName-hover {
  position: absolute;
  top: 830px;
  left: 45px;
  width: 273px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.bmpContactName-hover {
  position: absolute;
  top: 860px;
  left: 45px;
  width: 98.5px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.bmpContactPhone-hover {
  position: absolute;
  top: 860px;
  left: 354px;
  width: 31px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.bmpContactEmail-hover {
  position: absolute;
  top: 860px;
  left: 386px;
  width: 95px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.gradingPlan-hover {
  position: absolute;
  top: 147px;
  left: 57px;
  width: 166px;
  pointer-events: none;
  z-index: 1000;
  height: 20px;
}

.swmpPlan-hover {
  position: absolute;
  top: 180px;
  left: 57px;
  width: 372px;
  pointer-events: none;
  z-index: 1000;
  height: 20px;
}

.landscapePlan-hover {
  position: absolute;
  top: 212px;
  left: 57px;
  width: 108px;
  pointer-events: none;
  z-index: 1000;
  height: 20px;
}

.drainageReport-hover {
  position: absolute;
  top: 244px;
  left: 57px;
  width: 108px;
  pointer-events: none;
  z-index: 1000;
  height: 20px;
}

.soilsReport-hover {
  position: absolute;
  top: 276px;
  left: 57px;
  width: 169px;
  pointer-events: none;
  z-index: 1000;
  height: 20px;
}

.environmentalReport-hover {
  position: absolute;
  top: 308px;
  left: 57px;
  width: 220px;
  pointer-events: none;
  z-index: 1000;
  height: 20px;
}

.endangeredSpeciesReport-hover {
  position: absolute;
  top: 340px;
  left: 57px;
  width: 258px;
  pointer-events: none;
  z-index: 1000;
  height: 20px;
}

.archeologicalReport-hover {
  position: absolute;
  top: 372px;
  left: 57px;
  width: 137px;
  pointer-events: none;
  z-index: 1000;
  height: 20px;
}

.corpOfEngineersReport-hover {
  position: absolute;
  top: 404px;
  left: 57px;
  width: 262px;
  pointer-events: none;
  z-index: 1000;
  height: 20px;
}

.dustPermit-hover {
  position: absolute;
  top: 436px;
  left: 57px;
  width: 82px;
  pointer-events: none;
  z-index: 1000;
  height: 20px;
}

.constructionSchedule-hover {
  position: absolute;
  top: 468px;
  left: 57px;
  width: 147px;
  pointer-events: none;
  z-index: 1000;
  height: 20px;
}

.projectSpecifications-hover {
  position: absolute;
  top: 500px;
  left: 57px;
  width: 141px;
  pointer-events: none;
  z-index: 1000;
  height: 20px;
}

.inspectorQualifications-hover {
  position: absolute;
  top: 533px;
  left: 57px;
  width: 203px;
  pointer-events: none;
  z-index: 1000;
  height: 20px;
}

.dcName-hover {
  position: absolute;
  top: 671px;
  left: 64px;
  width: 215px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.dcPhone-hover {
  position: absolute;
  top: 671px;
  left: 607px;
  width: 50.5px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.dustPermitNumber-hover {
  position: absolute;
  top: 704.5px;
  left: 64px;
  width: 139px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.dcc-hover {
  position: absolute;
  top: 763px;
  left: 64px;
  width: 223.5px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.dccPhone-hover {
  position: absolute;
  top: 761.5px;
  left: 607px;
  width: 50.5px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.dccEmail-hover {
  position: absolute;
  top: 796.5px;
  left: 64px;
  width: 44px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.dccTitle-hover {
  position: absolute;
  top: 796.5px;
  left: 533px;
  width: 39px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.dustAuditContact-hover {
  position: absolute;
  top: 960px;
  left: 62px;
  width: 109.5px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.dustAuditContactEmail-hover {
  position: absolute;
  top: 960px;
  left: 451px;
  width: 99px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectionRecipient1-hover {
  position: absolute;
  top: 184px;
  left: 52px;
  width: 112px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectionRecipient1Title-hover {
  position: absolute;
  top: 216.5px;
  left: 52px;
  width: 39px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectionRecipient1Email-hover {
  position: absolute;
  top: 216.5px;
  left: 392px;
  width: 99.5px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectionRecipient2-hover {
  position: absolute;
  top: 288px;
  left: 52px;
  width: 112.5px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectionRecipient2Title-hover {
  position: absolute;
  top: 320.5px;
  left: 52px;
  width: 39px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectionRecipient2Email-hover {
  position: absolute;
  top: 320.5px;
  left: 392px;
  width: 99.5px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectionRecipient3-hover {
  position: absolute;
  top: 395px;
  left: 52px;
  width: 112.5px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectionRecipient3Title-hover {
  position: absolute;
  top: 428px;
  left: 52px;
  width: 39px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectionRecipient3Email-hover {
  position: absolute;
  top: 428px;
  left: 392px;
  width: 99.5px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectionRecipient4-hover {
  position: absolute;
  top: 507px;
  left: 52px;
  width: 112.5px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectionRecipient4Title-hover {
  position: absolute;
  top: 538px;
  left: 52px;
  width: 39.5px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectionRecipient4Email-hover {
  position: absolute;
  top: 538px;
  left: 392px;
  width: 99.5px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectingCompany-hover {
  position: absolute;
  top: 666px;
  left: 44px;
  width: 138px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectorName-hover {
  position: absolute;
  top: 666px;
  left: 434px;
  width: 111px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectorPhone-hover {
  position: absolute;
  top: 699px;
  left: 44px;
  width: 114px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectorEmail-hover {
  position: absolute;
  top: 699px;
  left: 435px;
  width: 99.5px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.additionalInformation-hover {
  position: absolute;
  top: 811px;
  left: 38px;
  width: 438px;
  height: 100px;
  pointer-events: none;
  z-index: 1000;
  height: 20px;
}

.startDate-hover-epa {
  position: absolute;
  top: 195px;
  left: 351px;
  width: 120px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.endDate-hover-epa {
  position: absolute;
  top: 195px;
  left: 605px;
  width: 92px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.projectName-hover-epa {
  position: absolute;
  top: 228px;
  left: 38px;
  width: 92px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.jobNumber-hover-epa {
  position: absolute;
  top: 228px;
  left: 570px;
  width: 65px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.accountingEmail-hover-epa {
  position: absolute;
  top: 255px;
  left: 38px;
  width: 178px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.regAgencySubmitDate-hover-epa {
  position: absolute;
  top: 255px;
  left: 468px;
  width: 230px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.sNum-hover-epa {
  position: absolute;
  top: 358px;
  left: 36px;
  width: 252px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.activeCDX-hover-epa {
  position: absolute;
  top: 385px;
  left: 36px;
  width: 261px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.cdxAccountManager-hover-epa {
  position: absolute;
  top: 415px;
  left: 36px;
  width: 162px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.manualLogsRequest-hover-epa {
  position: absolute;
  top: 390px;
  left: 476px;
  width: 182px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.cdxUserId-hover-epa {
  position: absolute;
  top: 443px;
  left: 36px;
  width: 64px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.dustAuditRequest-hover-epa {
  position: absolute;
  top: 430px;
  left: 476px;
  width: 202px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.noiRequest-hover-epa {
  position: absolute;
  top: 470px;
  left: 36px;
  width: 255px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.dustSignRequest-hover-epa {
  position: absolute;
  top: 471px;
  left: 476px;
  width: 122px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.inspectionRequest-hover-epa {
  position: absolute;
  top: 503px;
  left: 36px;
  width: 209px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.swpppSignRequest-hover-epa {
  position: absolute;
  top: 537px;
  left: 36px;
  width: 263px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.oName-hover-epa {
  position: absolute;
  top: 652px;
  left: 36px;
  width: 209px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.oPhone-hover-epa {
  position: absolute;
  top: 652px;
  left: 613px;
  width: 50px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.oAdd-hover-epa {
  position: absolute;
  top: 684px;
  left: 37px;
  width: 59px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.oCity-hover-epa {
  position: absolute;
  top: 684px;
  left: 448px;
  width: 31px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.oZip-hover-epa {
  position: absolute;
  top: 684px;
  left: 685px;
  width: 30px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.oContactName-hover-epa {
  position: absolute;
  top: 714px;
  left: 36px;
  width: 190px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.oContactTitle-hover-epa {
  position: absolute;
  top: 714px;
  left: 482px;
  width: 38px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.oContactPhone-hover-epa {
  position: absolute;
  top: 760px;
  left: 36px;
  width: 50px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.oContactEmail-hover-epa {
  position: absolute;
  top: 760px;
  left: 382px;
  width: 99px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.odrCompany-hover-epa {
  position: absolute;
  top: 843px;
  left: 36px;
  width: 236.5px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.odrName-hover-epa {
  position: absolute;
  top: 880px;
  left: 36px;
  width: 127px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.odrTitle-hover-epa {
  position: absolute;
  top: 880px;
  left: 459px;
  width: 38px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.odrPhone-hover-epa {
  position: absolute;
  top: 933px;
  left: 36px;
  width: 50px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.odrEmail-hover-epa {
  position: absolute;
  top: 933px;
  left: 368px;
  width: 96.5px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.gcName-hover-epa {
  position: absolute;
  top: 81px;
  left: 45px;
  width: 111.5px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.gcAdd-hover-epa {
  position: absolute;
  top: 109px;
  left: 45px;
  width: 59.5px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.gcCity-hover-epa {
  position: absolute;
  top: 109px;
  left: 406px;
  width: 35px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.gcZip-hover-epa {
  position: absolute;
  top: 109px;
  left: 674px;
  width: 31px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.gcPhone-hover-epa {
  position: absolute;
  top: 136px;
  left: 45px;
  width: 91px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.gcContactName-hover-epa {
  position: absolute;
  top: 164px;
  left: 45px;
  width: 217px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.gcContactTitle-hover-epa {
  position: absolute;
  top: 164px;
  left: 490px;
  width: 38px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.gcContactPhone-hover-epa {
  position: absolute;
  top: 211px;
  left: 45px;
  width: 50px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.gcContactEmail-hover-epa {
  position: absolute;
  top: 211px;
  left: 376px;
  width: 99px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.gcContact2Name-hover-epa {
  position: absolute;
  top: 251px;
  left: 45px;
  width: 49px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.gcContact2Title-hover-epa {
  position: absolute;
  top: 251px;
  left: 486px;
  width: 38px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.gcContact2Phone-hover-epa {
  position: absolute;
  top: 293px;
  left: 45px;
  width: 34px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.gcContact2Email-hover-epa {
  position: absolute;
  top: 293px;
  left: 377px;
  width: 99.5px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.gcContact3Name-hover-epa {
  position: absolute;
  top: 329px;
  left: 45px;
  width: 49px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.gcContact3Title-hover-epa {
  position: absolute;
  top: 329px;
  left: 485px;
  width: 38px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.gcContact3Phone-hover-epa {
  position: absolute;
  top: 371px;
  left: 45px;
  width: 34px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.gcContact3Email-hover-epa {
  position: absolute;
  top: 371px;
  left: 376px;
  width: 99.5px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.pAdd-hover-epa {
  position: absolute;
  top: 453px;
  left: 45px;
  width: 128px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.totAcr-hover-epa {
  position: absolute;
  top: 492px;
  left: 45px;
  width: 162.5px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.distAcr-hover-epa {
  position: absolute;
  top: 492px;
  left: 436.5px;
  width: 152px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.scopeOfWork-hover-epa {
  position: absolute;
  top: 528px;
  left: 45px;
  width: 102px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.emergencyContactName-hover-epa {
  position: absolute;
  top: 608px;
  left: 45px;
  width: 229px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.emergencyContactTitle-hover-epa {
  position: absolute;
  top: 608px;
  left: 515px;
  width: 39px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.emergencyContactPhone-hover-epa {
  position: absolute;
  top: 643px;
  left: 45px;
  width: 33px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.emergencyContactEmail-hover-epa {
  position: absolute;
  top: 643px;
  left: 348px;
  width: 99px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.prevAgricultureLand-hover-epa {
  position: absolute;
  top: 677px;
  left: 45px;
  width: 325px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.demolitionActivities-hover-epa {
  position: absolute;
  top: 709px;
  left: 45px;
  width: 405px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.dewateringDischarge-hover-epa {
  position: absolute;
  top: 740px;
  left: 45px;
  width: 360px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.laydownYard-hover-epa {
  position: absolute;
  top: 773px;
  left: 45px;
  width: 427px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.borrowArea-hover-epa {
  position: absolute;
  top: 805px;
  left: 45px;
  width: 482px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.portableTanksOnsite-hover-epa {
  position: absolute;
  top: 838px;
  left: 45px;
  width: 311px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.portableTankCapacity-hover-epa {
  position: absolute;
  top: 838px;
  left: 535px;
  width: 63px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.bmpCompanyName-hover-epa {
  position: absolute;
  top: 874px;
  left: 45px;
  width: 252px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.bmpContactName-hover-epa {
  position: absolute;
  top: 902px;
  left: 45px;
  width: 98.5px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.bmpContactPhone-hover-epa {
  position: absolute;
  top: 902px;
  left: 354px;
  width: 31px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.bmpContactEmail-hover-epa {
  position: absolute;
  top: 902px;
  left: 385px;
  width: 95px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.gradingPlan-hover-epa {
  position: absolute;
  top: 132px;
  left: 45px;
  width: 166px;
  pointer-events: none;
  z-index: 1000;
  height: 20px;
}

.swmpPlan-hover-epa {
  position: absolute;
  top: 163px;
  left: 45px;
  width: 372px;
  pointer-events: none;
  z-index: 1000;
  height: 20px;
}

.landscapePlan-hover-epa {
  position: absolute;
  top: 194px;
  left: 45px;
  width: 108px;
  pointer-events: none;
  z-index: 1000;
  height: 20px;
}

.drainageReport-hover-epa {
  position: absolute;
  top: 226px;
  left: 45px;
  width: 107px;
  pointer-events: none;
  z-index: 1000;
  height: 20px;
}

.soilsReport-hover-epa {
  position: absolute;
  top: 258px;
  left: 45px;
  width: 169px;
  pointer-events: none;
  z-index: 1000;
  height: 20px;
}

.environmentalReport-hover-epa {
  position: absolute;
  top: 290px;
  left: 45px;
  width: 220px;
  pointer-events: none;
  z-index: 1000;
  height: 20px;
}

.endangeredSpeciesReport-hover-epa {
  position: absolute;
  top: 323px;
  left: 45px;
  width: 258px;
  pointer-events: none;
  z-index: 1000;
  height: 20px;
}

.archeologicalReport-hover-epa {
  position: absolute;
  top: 356px;
  left: 45px;
  width: 137px;
  pointer-events: none;
  z-index: 1000;
  height: 20px;
}

.listOfSubcontractors-hover-epa {
  position: absolute;
  top: 388px;
  left: 45px;
  width: 137px;
  pointer-events: none;
  z-index: 1000;
  height: 20px;
}

.corpOfEngineersReport-hover-epa {
  position: absolute;
  top: 420px;
  left: 45px;
  width: 260px;
  pointer-events: none;
  z-index: 1000;
  height: 20px;
}

.dustPermit-hover-epa {
  position: absolute;
  top: 453px;
  left: 45px;
  width: 82px;
  pointer-events: none;
  z-index: 1000;
  height: 20px;
}

.constructionSchedule-hover-epa {
  position: absolute;
  top: 485px;
  left: 45px;
  width: 145px;
  pointer-events: none;
  z-index: 1000;
  height: 20px;
}

.projectSpecifications-hover-epa {
  position: absolute;
  top: 515px;
  left: 45px;
  width: 141px;
  pointer-events: none;
  z-index: 1000;
  height: 20px;
}

.inspectorQualifications-hover-epa {
  position: absolute;
  top: 547px;
  left: 45px;
  width: 201px;
  pointer-events: none;
  z-index: 1000;
  height: 20px;
}

.dcName-hover-epa {
  position: absolute;
  top: 681px;
  left: 64px;
  width: 215px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.dcPhone-hover-epa {
  position: absolute;
  top: 681px;
  left: 607px;
  width: 50.5px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.dustPermitNumber-hover-epa {
  position: absolute;
  top: 715px;
  left: 64px;
  width: 139px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.dcc-hover-epa {
  position: absolute;
  top: 773px;
  left: 64px;
  width: 223.5px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.dccPhone-hover-epa {
  position: absolute;
  top: 773px;
  left: 607px;
  width: 50.5px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.dccEmail-hover-epa {
  position: absolute;
  top: 806px;
  left: 64px;
  width: 44px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.dccTitle-hover-epa {
  position: absolute;
  top: 806px;
  left: 533px;
  width: 39px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.dustAuditContact-hover-epa {
  position: absolute;
  top: 970px;
  left: 62px;
  width: 109.5px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.dustAuditContactEmail-hover-epa {
  position: absolute;
  top: 970px;
  left: 451px;
  width: 99px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectionRecipient1-hover-epa {
  position: absolute;
  top: 184px;
  left: 52px;
  width: 112px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectionRecipient1Title-hover-epa {
  position: absolute;
  top: 216.5px;
  left: 52px;
  width: 39px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectionRecipient1Email-hover-epa {
  position: absolute;
  top: 216.5px;
  left: 392px;
  width: 99.5px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectionRecipient2-hover-epa {
  position: absolute;
  top: 288px;
  left: 52px;
  width: 112.5px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectionRecipient2Title-hover-epa {
  position: absolute;
  top: 320.5px;
  left: 52px;
  width: 39px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectionRecipient2Email-hover-epa {
  position: absolute;
  top: 320.5px;
  left: 392px;
  width: 99.5px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectionRecipient3-hover-epa {
  position: absolute;
  top: 395px;
  left: 52px;
  width: 112.5px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectionRecipient3Title-hover-epa {
  position: absolute;
  top: 428px;
  left: 52px;
  width: 39px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectionRecipient3Email-hover-epa {
  position: absolute;
  top: 428px;
  left: 392px;
  width: 99.5px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectionRecipient4-hover-epa {
  position: absolute;
  top: 507px;
  left: 52px;
  width: 112.5px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectionRecipient4Title-hover-epa {
  position: absolute;
  top: 538px;
  left: 52px;
  width: 39.5px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectionRecipient4Email-hover-epa {
  position: absolute;
  top: 538px;
  left: 392px;
  width: 99.5px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectingCompany-hover-epa {
  position: absolute;
  top: 666px;
  left: 44px;
  width: 138px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectorName-hover-epa {
  position: absolute;
  top: 666px;
  left: 434px;
  width: 111px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectorPhone-hover-epa {
  position: absolute;
  top: 699px;
  left: 44px;
  width: 114px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectorEmail-hover-epa {
  position: absolute;
  top: 699px;
  left: 435px;
  width: 99.5px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.additionalInformation-hover-epa {
  position: absolute;
  top: 811px;
  left: 38px;
  width: 438px;
  height: 100px;
  pointer-events: none;
  z-index: 1000;
  height: 20px;
}

.startDate-hover-swmp {
  position: absolute;
  top: 195px;
  left: 352px;
  width: 119px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.duration-hover-swmp {
  position: absolute;
  top: 197px;
  left: 610px;
  width: 114px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.projectName-hover-swmp {
  position: absolute;
  top: 226px;
  left: 37px;
  width: 92px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.wsNumber-hover-swmp {
  position: absolute;
  top: 226px;
  left: 605px;
  width: 40px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.jobNumber-hover-swmp {
  position: absolute;
  top: 256px;
  left: 37px;
  width: 60px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.accountingEmail-hover-swmp {
  position: absolute;
  top: 256px;
  left: 266px;
  width: 176px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.submittingToRegAgency-hover-swmp {
  position: absolute;
  top: 287px;
  left: 37px;
  width: 368px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.regAgencySubmitDate-hover-swmp {
  position: absolute;
  top: 287px;
  left: 559px;
  width: 103px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.sNum-hover-swmp {
  position: absolute;
  top: 414px;
  left: 52px;
  width: 257px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.manualLogsRequest-hover-swmp {
  position: absolute;
  top: 416px;
  left: 437px;
  width: 184px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.inspectionRequest-hover-swmp {
  position: absolute;
  top: 448px;
  left: 52px;
  width: 210px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.dustAuditRequest-hover-swmp {
  position: absolute;
  top: 450px;
  left: 436px;
  width: 202px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.oName-hover-swmp {
  position: absolute;
  top: 561px;
  left: 35px;
  width: 160px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.oPhone-hover-swmp {
  position: absolute;
  top: 561px;
  left: 578px;
  width: 50px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.oAdd-hover-swmp {
  position: absolute;
  top: 592px;
  left: 35px;
  width: 60px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.oCity-hover-swmp {
  position: absolute;
  top: 593px;
  left: 403px;
  width: 34px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.oZip-hover-swmp {
  position: absolute;
  top: 592px;
  left: 681px;
  width: 30px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.waterServicesContact-hover-swmp {
  position: absolute;
  top: 623px;
  left: 34px;
  width: 185px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.waterServicesPhone-hover-swmp {
  position: absolute;
  top: 654px;
  left: 35px;
  width: 50px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.waterServicesEmail-hover-swmp {
  position: absolute;
  top: 654px;
  left: 374px;
  width: 99px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.gcName-hover-swmp {
  position: absolute;
  top: 745px;
  left: 38px;
  width: 109px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.gcAdd-hover-swmp {
  position: absolute;
  top: 774px;
  left: 38px;
  width: 60px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.gcCity-hover-swmp {
  position: absolute;
  top: 774px;
  left: 403px;
  width: 35px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.gcZip-hover-swmp {
  position: absolute;
  top: 774px;
  left: 680px;
  width: 31px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.gcPhone-hover-swmp {
  position: absolute;
  top: 801px;
  left: 38px;
  width: 91px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.projectManager-hover-swmp {
  position: absolute;
  top: 846px;
  left: 38px;
  width: 116px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.projectManagerPhone-hover-swmp {
  position: absolute;
  top: 873px;
  left: 38px;
  width: 50px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.projectManagerEmail-hover-swmp {
  position: absolute;
  top: 873px;
  left: 371px;
  width: 99px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.superintendent-hover-swmp {
  position: absolute;
  top: 916px;
  left: 38px;
  width: 151px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.superintendentPhone-hover-swmp {
  position: absolute;
  top: 952px;
  left: 38px;
  width: 35px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.superintendentEmail-hover-swmp {
  position: absolute;
  top: 952px;
  left: 371px;
  width: 99px;
  pointer-events: none;
  z-index: 1000;
  height: 27px;
}

.pAdd-hover-swmp {
  position: absolute;
  top: 73px;
  left: 44px;
  width: 148px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.totAcr-hover-swmp {
  position: absolute;
  top: 110px;
  left: 44px;
  width: 163px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.distAcr-hover-swmp {
  position: absolute;
  top: 110px;
  left: 436.5px;
  width: 152px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.scopeOfWork-hover-swmp {
  position: absolute;
  top: 148px;
  left: 44px;
  width: 102px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.emergencyContactName-hover-swmp {
  position: absolute;
  top: 251px;
  left: 44px;
  width: 229px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.emergencyContactTitle-hover-swmp {
  position: absolute;
  top: 251px;
  left: 584px;
  width: 39px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.emergencyContactPhone-hover-swmp {
  position: absolute;
  top: 292px;
  left: 44px;
  width: 33px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.emergencyContactEmail-hover-swmp {
  position: absolute;
  top: 292px;
  left: 365px;
  width: 99px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.laydownYard-hover-swmp {
  position: absolute;
  top: 336px;
  left: 43px;
  width: 425px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.borrowArea-hover-swmp {
  position: absolute;
  top: 378px;
  left: 43px;
  width: 465px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.portableTanksOnsite-hover-swmp {
  position: absolute;
  top: 421px;
  left: 43px;
  width: 309px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.portableTankCapacity-hover-swmp {
  position: absolute;
  top: 423px;
  left: 552px;
  width: 62px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.bmpCompanyName-hover-swmp {
  position: absolute;
  top: 492px;
  left: 43px;
  width: 273px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.bmpContactName-hover-swmp {
  position: absolute;
  top: 520px;
  left: 43px;
  width: 98.5px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.bmpContactPhone-hover-swmp {
  position: absolute;
  top: 520px;
  left: 352px;
  width: 31px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.bmpContactEmail-hover-swmp {
  position: absolute;
  top: 520px;
  left: 384px;
  width: 95px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.gradingPlan-hover-swmp {
  position: absolute;
  top: 675px;
  left: 51px;
  width: 167px;
  pointer-events: none;
  z-index: 1000;
  height: 20px;
}

.swmpPlan-hover-swmp {
  position: absolute;
  top: 705px;
  left: 51px;
  width: 372px;
  pointer-events: none;
  z-index: 1000;
  height: 20px;
}

.dustPermit-hover-swmp {
  position: absolute;
  top: 738px;
  left: 51px;
  width: 82px;
  pointer-events: none;
  z-index: 1000;
  height: 20px;
}

.constructionSchedule-hover-swmp {
  position: absolute;
  top: 770px;
  left: 51px;
  width: 260px;
  pointer-events: none;
  z-index: 1000;
  outline: 2px dashed #0fda34;
  background-color: #00ff2f5d;
  height: 20px;
}

.projectSpecifications-hover-swmp {
  position: absolute;
  top: 802px;
  left: 51px;
  width: 141px;
  pointer-events: none;
  z-index: 1000;
  outline: 2px dashed #0fda34;
  background-color: #00ff2f5d;
  height: 20px;
}

.inspectorQualifications-hover-swmp {
  position: absolute;
  top: 833px;
  left: 51px;
  width: 203px;
  pointer-events: none;
  z-index: 1000;
  outline: 2px dashed #0fda34;
  background-color: #00ff2f5d;
  height: 20px;
}

/* Questionnaire Page 4 */
.inspectionRecipient1-hover-swmp {
  position: absolute;
  top: 171px;
  left: 50px;
  width: 110px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectionRecipient1Title-hover-swmp {
  position: absolute;
  top: 203px;
  left: 50px;
  width: 39px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectionRecipient1Email-hover-swmp {
  position: absolute;
  top: 203px;
  left: 389px;
  width: 99px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectionRecipient2-hover-swmp {
  position: absolute;
  top: 275px;
  left: 50px;
  width: 110px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectionRecipient2Title-hover-swmp {
  position: absolute;
  top: 307px;
  left: 50px;
  width: 39px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectionRecipient2Email-hover-swmp {
  position: absolute;
  top: 307px;
  left: 390px;
  width: 99px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectionRecipient3-hover-swmp {
  position: absolute;
  top: 380px;
  left: 50px;
  width: 110px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectionRecipient3Title-hover-swmp {
  position: absolute;
  top: 414px;
  left: 50px;
  width: 39px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectionRecipient3Email-hover-swmp {
  position: absolute;
  top: 414px;
  left: 390px;
  width: 99px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectionRecipient4-hover-swmp {
  position: absolute;
  top: 493px;
  left: 50px;
  width: 112.5px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectionRecipient4Title-hover-swmp {
  position: absolute;
  top: 524px;
  left: 50px;
  width: 39px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectionRecipient4Email-hover-swmp {
  position: absolute;
  top: 524px;
  left: 390px;
  width: 99.5px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectingCompany-hover-swmp {
  position: absolute;
  top: 636px;
  left: 47px;
  width: 138px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectorName-hover-swmp {
  position: absolute;
  top: 636px;
  left: 436px;
  width: 111px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectorPhone-hover-swmp {
  position: absolute;
  top: 668px;
  left: 47px;
  width: 113px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.inspectorEmail-hover-swmp {
  position: absolute;
  top: 668px;
  left: 437px;
  width: 99px;
  pointer-events: none;
  z-index: 1000;
  height: 25px;
}

.additionalInformation-hover-swmp {
  position: absolute;
  top: 795px;
  left: 38px;
  width: 438px;
  height: 100px;
  pointer-events: none;
  z-index: 1000;
  height: 20px;
}

@media screen and (1650px <= width < 1735px) {
  #main-pdf-container {
    min-width: 765px;
    max-width: 765px;
  }
}

@media screen and (1735px <= width < 1800px) {
  #main-pdf-container {
    min-width: 825px;
    max-width: 825px;
  }
}

@media screen and (width < 1875px) {
  #main-pdf-container {
    min-width: 900px;
    max-width: 900px;
  }
}

@media screen and (1875px <= width < 2000px) {
  #main-pdf-container {
    width: 925px;
  }
}

@media screen and (width >= 2000px) {
  #main-pdf-container {
    width: 1050px;
  }
}
