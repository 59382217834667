#main-validation-container {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  align-self: flex-start !important;
  width: 100vw;
  height: auto;
  max-height: 100%;
  top: 56px;
  margin: 0;
  padding: 0;
  position: relative;
}

#submit-check-snackbar,
#submit-success-snackbar,
#submit-error-snackbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
  width: 995px;
}

#error-snackbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
  width: 1100px;
}

#submit-check-alert {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  background-color: rgba(255, 190, 137, 0.879) !important;
  border: 2px solid rgb(221, 100, 0);
  width: 94%;
}

#validate-page-check-alert,
#validate-form-check-alert {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  background-color: rgba(254, 197, 150, 0.963) !important;
  border: 2px solid rgb(221, 100, 0);
  min-width: 650px;
  width: auto;
  max-width: max-content;
}

.validate-page-action,
.validate-form-action {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

#cancel-clear-btn,
#cancel-submit-btn {
  background-color: var(--btn-red);
  color: var(--btn-white-text);
  font-family: var(--default-font);
  font-size: 11px;
  font-weight: var(--default-font-weight);
  padding: 0;
  height: 25px;
  width: 55px;
}

#cancel-clear-btn:hover,
#cancel-submit-btn:hover {
  background-color: var(--btn-red-hover);
  cursor: pointer;
}

#validate-clear-btn,
#validate-submit-btn {
  background-color: var(--btn-green);
  color: var(--btn-white-text);
  font-family: var(--default-font);
  font-size: 11px;
  font-weight: var(--default-font-weight);
  padding: 0;
  height: 25px;
  width: 75px;
}

#validate-clear-btn:hover,
#validate-submit-btn:hover {
  background-color: var(--btn-green-hover);
  cursor: pointer;
}

#submit-success-alert {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  background-color: rgba(191, 247, 191, 0.985) !important;
  outline: 2px solid rgb(3, 167, 3);
}

#submit-error-alert,
#error-alert {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  background-color: rgba(255, 187, 187, 0.985) !important;
  outline: 2px solid rgb(167, 3, 3);
}

#submit-check-alert .MuiAlert-message,
#validate-page-check-alert .MuiAlert-message,
#validate-form-check-alert .MuiAlert-message {
  font-family: var(--default-font);
  font-weight: var(--default-font-weight);
  font-size: 14px;
  letter-spacing: 0.25px;
  width: fit-content;
  text-align: center;
  max-width: 700px;
  color: rgb(209, 83, 15);
}

#submit-success-alert .MuiAlert-message {
  font-family: var(--default-font);
  font-size: 15px;
  letter-spacing: 0.25px;
  width: fit-content;
  max-width: 700px;
  color: rgb(6, 115, 6);
}

#submit-error-alert .MuiAlert-message {
  font-family: var(--default-font);
  font-size: 15px;
  letter-spacing: 0.25px;
  width: fit-content;
  max-width: 700px;
  color: rgb(115, 6, 6);
}

#error-alert .MuiAlert-message {
  font-family: var(--default-font);
  font-size: 15px;
  letter-spacing: 0.25px;
  width: fit-content;
  max-width: 900px;
  color: rgb(115, 6, 6);
}

#submit-check-alert .MuiAlert-action,
#submit-error-alert .MuiAlert-action {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  margin: 0 0 0 15px;
  padding: 0;
}

#submit-success-alert .MuiAlert-action {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  margin: 0 0 0 15px;
  padding: 0;
  color: rgb(6, 115, 6);
}

#submit-success-alert .MuiAlert-action .MuiSvgIcon-root {
  width: 25px;
  height: 25px;
  margin: 0;
  padding: 0;
  fill: rgb(6, 115, 6);
}

#action-cancel-btn,
#action-submit-btn,
#error-close-btn {
  height: 25px;
  width: 55px;
  font-size: 12px;
  margin: 0 5px !important;
}

#action-cancel-btn,
#error-close-btn {
  color: rgb(240, 240, 240);
  background-color: rgb(204, 5, 5);
}

#action-cancel-btn:hover,
#error-close-btn:hover {
  background-color: rgb(163, 0, 0);
  cursor: pointer;
}

#action-submit-btn {
  color: rgb(240, 240, 240);
  background-color: #4fcb1e;
}

#action-submit-btn:hover {
  background-color: #3eb60b;
  cursor: pointer;
}

#submit-check-alert .MuiAlert-icon .MuiSvgIcon-root,
#validate-page-check-alert .MuiAlert-icon .MuiSvgIcon-root {
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;
  fill: rgb(225, 89, 21);
}

#submit-success-alert .MuiAlert-icon .MuiSvgIcon-root {
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;
  fill: rgb(5, 145, 5);
}

#submit-error-alert .MuiAlert-icon .MuiSvgIcon-root,
#error-alert .MuiAlert-icon .MuiSvgIcon-root {
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;
  fill: rgb(167, 3, 3);
}

#submit-check-alert .MuiAlert-icon {
  margin: 0 15px 0 0;
}

#submit-questionnaire-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100vw;
  background-color: rgba(79, 79, 79, 0.37);
  margin: 0;
  padding: 0;
  z-index: 99999 !important;
}

.submit-questionnaire-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  background-color: rgb(255, 218, 183);
  border: 3px solid rgb(242, 89, 0);
  max-width: 70%;
  min-width: 800px;
  width: fit-content;
  height: 220px;
  border-radius: 5px;
}

.submit-questionnaire-modal-content:focus-visible {
  outline: none;
}

#check-submit-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: 100%;
  width: 100%;
}

#check-submit-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
}

#submit-check-header {
  font-family: var(--default-font);
  font-size: 28px;
  font-weight: var(--font-weight-bold);
  color: rgb(242, 89, 0);
  margin: 10px 0 0 0 !important;
  padding: 0;
}

#check-submit-hr {
  width: 100%;
  padding: 0;
  margin: 0 !important;
  border: none;
  border-top: 3px solid rgb(242, 89, 0);
}

#check-submit-message {
  font-family: var(--default-font);
  font-size: 18px;
  font-weight: var(--default-font-weight);
  color: rgb(242, 89, 0);
  margin: 0 0 auto 0 !important;
  padding: 0;
  max-width: 780px;
  text-align: center;
}

#check-submit-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  margin: 0 0 15px 0 !important;
}

#cancel-submit-questionnaire-btn {
  color: var(--btn-white-text);
  background-color: var(--btn-red);
  font-size: 15px;
  line-height: 1;
  font-weight: var(--font-weight-bold);
  font-family: var(--default-font);
  height: 35px;
}

#cancel-submit-questionnaire-btn:hover {
  background-color: var(--btn-red-hover);
}

#validate-submit-questionnaire-btn {
  color: var(--btn-white-text);
  background-color: var(--btn-green);
  font-size: 15px;
  line-height: 1;
  font-weight: var(--font-weight-bold);
  font-family: var(--default-font);
  height: 35px;
}

#validate-submit-questionnaire-btn:hover {
  background-color: var(--btn-green-hover);
}

@media screen and (1830px <= width < 1875px) {
  #main-validation-container {
    gap: 5px;
  }
}

@media screen and (1875px <= width) {
  #main-validation-container {
    gap: 10;
  }
}
