.custom-invite-modal {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 100vw;
  height: calc(100% - 160px) !important;
  min-height: calc(100% - 160px) !important;
  background-color: rgba(64, 64, 64, 0.371);
  position: fixed;
  top: 161px !important;
  pointer-events: auto;
}

#attach-invite-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  gap: 50px;
}

.invite-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  position: relative;
  /* top: 45px; */
  gap: 20px;
  border: none;
  max-width: 700px;
}

#email-header-logo {
  height: 70px;
  width: 250px;
  padding: 10px 10px 10px 15px;
}

.email-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: rgb(222, 222, 222);
}

.email-fake-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 50px;
  margin-bottom: 20px;
}

#email-fake-btn {
  color: white;
  background-color: #4caf50;
  font-size: 15px;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: 500;
  font-family: "Arial", sans-serif;
  cursor: not-allowed;
}

.email-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 25px;
  margin-bottom: 20px;
}

.email-message-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 98%;
  height: max-content;
  margin-top: 15px;
}

.email-content-label {
  color: rgb(28, 28, 28);
  font-size: 20px;
  font-family: "Arial", sans-serif;
  font-weight: 700;
}

.email-message {
  color: rgb(60, 60, 60);
  font-family: "Arial", sans-serif;
  font-weight: 550;
  width: 100%;
  max-height: 150px;
  min-height: 95px;
  background-color: transparent;
  justify-self: center;
}

#email-message-textarea {
  resize: none;
  border: 1px solid rgb(51, 51, 51);
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
}

#email-message-p {
  white-space: pre-line;
  font-size: 20px;
}

.email-code-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 98%;
  height: max-content;
}

#email-code {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(60, 60, 60);
  font-size: 20px;
  font-family: "Arial", sans-serif;
  font-weight: 550;
  width: 100%;
  justify-self: center;
  gap: 6px;
}

#invite-user-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  gap: 20px;
}

.default-message-btn#default-message-btn {
  color: var(--btn-white-text);
  background-color: var(--btn-red);
  font-family: var(--default-font) !important;
  font-weight: var(--font-weight-bold);
  line-height: 1;
  padding: 10px 8px;
  font-size: 15px;
}

.default-message-btn#default-message-btn:hover {
  background-color: var(--btn-red-hover);
}

.default-message-btn#default-message-btn:disabled {
  background-color: rgba(185, 185, 185, 0.549);
  color: var(--disabled-btn-color);
  pointer-events: none;
  opacity: 0.6;
  box-shadow: none;
}

.edit-message-btn#edit-message-btn {
  color: var(--btn-white-text);
  background-color: rgb(255, 144, 17);
  font-family: var(--default-font) !important;
  font-weight: var(--font-weight-bold);
  line-height: 1;
  font-size: 15px;
  padding: 10px 10px;
}

.edit-message-btn#edit-message-btn:hover {
  background-color: rgb(245, 131, 0);
}

.edit-message-btn#edit-message-btn:disabled {
  background-color: rgba(185, 185, 185, 0.549);
  color: var(--disabled-btn-color);
  pointer-events: none;
  opacity: 0.6;
  box-shadow: none;
}

.send-message-btn#send-message-btn {
  color: var(--btn-white-text);
  background-color: var(--btn-green);
  font-family: var(--default-font) !important;
  font-weight: var(--font-weight-bold);
  line-height: 1;
  font-size: 15px;
  padding: 10px 8px;
}

.send-message-btn#send-message-btn:hover {
  background-color: var(--btn-green-hover);
}

#send-email-btn.send-email-btn {
  color: var(--btn-white-text);
  background-color: var(--btn-green);
  font-family: var(--default-font) !important;
  font-size: 14px;
  font-weight: var(--font-weight-bold) !important;
  margin: 0 0 10px 0;
  height: 38px;
  line-height: 1;
  width: 190px;
  letter-spacing: 0.2px;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(3, 8, 15, 0.09) 0px 1px 1px,
    rgba(2, 1, 15, 0.09) 0px 2px 3px, rgba(0, 0, 0, 0.09) 0px 4px 4px;
  transition: all 350ms ease-in-out;
  pointer-events: auto;
}

#send-email-btn.send-email-btn.Mui-disabled {
  background-color: var(--disabled-btn-bg);
  color: var(--disabled-btn-color);
  pointer-events: none;
}

#send-email-btn.send-email-btn.Mui-disabled:hover {
  cursor: not-allowed;
}

#send-email-btn.send-email-btn:hover {
  background-color: var(--btn-green-hover);
  cursor: pointer;
}

@media screen and (width < 1450px) {
  #send-email-btn.send-email-btn {
    width: 178px;
    height: 32px;
    font-size: 13px;
  }

  .invite-modal-container {
    scale: 0.88;
  }
}

@media screen and (1450px <= width < 1750px) {
  #send-email-btn.send-email-btn {
    width: 190px;
    height: 36px;
    font-size: 14px;
  }

  .invite-modal-container {
    scale: 0.92;
  }
}
