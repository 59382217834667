#validate-modal.modal-root {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

#validate-modal.modal-root .modal-backdrop {
  background-color: rgba(0, 0, 0, 0.514);
}

#chip-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(32, 137, 158);
  border: 3px solid var(--label-color);
}

#chip-modal-hr {
  margin: 10px 0px 10px 0px;
  border-bottom: 2px solid var(--label-color);
  width: 100%;
}

#modal-chip-header {
  margin: 10px 0 0 0;
  font-size: 30px;
  font-family: var(--default-font);
  color: var(--label-color);
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.266);
}

#modal-description {
  width: 740px;
  height: fit-content;
  padding: 0px 0px 0px 0px;
  margin: 5px 0px 0px 0px;
  text-align: center;
  color: var(--label-color);
  font-family: var(--default-font);
  font-weight: var(--default-font-weight);
}

.modal-switch-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 745px;
  margin: 10px 0px;
}

#label-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 290px;
}

#modal-switch-form-control-label.modal-switch-formControlLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 98px;
  padding: 0 !important;
  margin: 0 !important;
}

#modal-switch-form-control-label.modal-switch-formControlLabel.invalid {
  width: 108px;
  position: relative;
  left: 14px;
}

#modal-switch-form-control-label .switch-form-label {
  color: var(--label-color);
  font-weight: var(--default-font-weight);
  font-family: var(--default-font);
  font-size: 16px;
  margin: 0 !important;
  padding: 0 !important;
}

#modal-switch-label {
  width: max-content;
  color: var(--label-color);
  font-weight: var(--default-font-weight);
  font-family: var(--default-font);
  font-size: 19px;
}

.modal-switch.modal-switch-root {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: auto;
  padding: 0;
  overflow: visible;
}

.modal-switch.modal-switch-root .modal-switch-base {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  margin: 0 !important;
  transition-duration: 325ms;
  transform: translate(0, 0);
}

.modal-switch.modal-switch-root .modal-switch-base .modal-switch-base-input {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-self: center;
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
}

.modal-switch .modal-switch-base .modal-switch-thumb {
  position: absolute;
  left: 0;
  top: 0;
  box-sizing: border-box;
  height: 17px;
  width: 17px;
  background-color: var(--label-color);
}

.modal-switch .modal-switch-track {
  border-radius: 10px;
  background-color: #a8a8a8;
  width: 34px;
  opacity: 1;
  transition: background-color 320ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  height: 17px;
}

.modal-switch.modal-switch-root .modal-switch-base.modal-switch-checked {
  transform: translate(16.5px, 0);
  color: var(--label-color);
}

.modal-switch.modal-switch-root
  .modal-switch-base.modal-switch-checked
  + .modal-switch-track {
  background-color: #52df3f;
  opacity: 1;
  border: 0;
}

.modal-switch.modal-switch-root
  .modal-switch-base.modal-switch-checked
  .Mui-disabled
  + .modal-switch-track {
  opacity: 0.5;
}

.modal-text-container {
  display: flex;
  align-items: center;
  justify-content: space-around !important;
  width: 745px;
  height: 90px;
  margin: 5px 0px;
}

#modal-input-label {
  width: max-content;
  color: var(--label-color);
  font-weight: var(--default-font-weight);
  font-family: var(--default-font);
  font-size: 19px;
  margin-right: auto;
}

#validate-text-form {
  box-shadow:
    rgba(0, 0, 0, 0.06) 0px 2px 1px,
    rgba(3, 8, 15, 0.09) 0px 1px 1px,
    rgba(0, 0, 0, 0.217) 0px 4px 5px,
    rgba(2, 1, 15, 0.09) 0px 2px 3px,
    rgba(0, 0, 0, 0.09) 0px 6px 6px;
  background-color: rgb(244, 244, 244);
  border-radius: 5px;
  border: none;
  outline: none;
  width: 435px;
  height: 40px;
}

.validate-text-root {
  display: flex;
  align-self: flex-start;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  border: none;
  outline: none;
  padding: 0 !important;
  margin: 0 !important;
  height: 100%;
  width: 100%;
}

.validate-text-notchedOutline {
  border: none !important;
  outline: none !important;
}

.validate-text-notchedOutline:focus-within {
  border: none !important;
  outline: none !important;
}

.validate-text-input#validate-text-field {
  color: rgb(20, 20, 20);
  font-size: 18px;
  box-shadow: none;
  text-indent: 8px;
  padding: 0 !important;
  margin: 0 !important;
  font-weight: var(--default-font-weight);
  border-radius: 5px;
  z-index: 999;
  letter-spacing: 0.7px;
  font-family: var(--default-font);
  height: inherit;
  background-color: transparent;
  height: 40px;
}

#validate-text-label {
  font-family: var(--default-font);
  color: rgb(125, 125, 125) !important;
  font-weight: 550;
  transform: translate(14px, 9px) scale(1);
}

#validate-text-label.validate-input-label-shrink {
  color: rgb(240, 240, 240) !important;
  transform: translate(3px, -21px) scale(0.75) !important;
  letter-spacing: 0.7px;
}

.helper-text.validate-field-helper {
  text-align: center;
  color: rgb(246, 218, 34);
  font-family: var(--default-font);
  font-weight: var(--default-font-weight);
  font-size: 13px;
  min-width: 435px;
  margin-left: 0;
}

.modal-message-container {
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
  width: 745px;
  margin: 10px 0px;
  height: 125px;
  font-family: var(--default-font);
}

#modal-message-label {
  width: max-content;
  color: var(--label-color);
  font-weight: var(--default-font-weight);
  font-family: var(--default-font);
  font-size: 19px;
  margin-right: auto;
}

.message-input {
  height: 105px;
  width: 530px !important;
  margin: 0 5px 0 0;
  padding: 5px;
  font-size: 17px;
  color: rgb(36, 36, 36);
  font-weight: var(--default-font-weight);
  text-indent: 5px;
  font-family: var(--default-font);
  resize: none;
  border-radius: 5px;
  box-shadow:
    rgba(0, 0, 0, 0.06) 0px 2px 1px,
    rgba(3, 8, 15, 0.09) 0px 1px 1px,
    rgba(0, 0, 0, 0.217) 0px 4px 5px,
    rgba(2, 1, 15, 0.09) 0px 2px 3px,
    rgba(0, 0, 0, 0.09) 0px 6px 6px;
  border: none;
}

.message-input:focus,
.message-input:focus-within {
  outline: none;
  border: none;
}

.modal-input-text {
  pointer-events: auto;
  cursor: text;
  font-family: var(--default-font);
  font-size: 18px;
  letter-spacing: 0.5px;
  font-weight: var(--default-font-weight);
  color: rgb(36, 36, 36);
}

.modal-input-text.Mui-disabled {
  color: rgb(65, 65, 65);
}

.text-input-text input:disabled {
  font-family: var(--default-font);
  font-size: 16px;
  font-weight: 500;
  color: rgb(36, 36, 36) !important;
}

.text-input-helper {
  position: relative;
  color: var(--label-color) !important;
  width: fit-content;
  margin: 5px;
  pointer-events: none;
  cursor: default;
  font-family: var(--default-font);
}

#model-input-label {
  width: max-content;
}

#text-input-label.MuiInputLabel-shrink {
  color: rgb(240, 240, 240) !important;
  transform: translate(3px, -16px) scale(0.75) !important;
  letter-spacing: 0.7px;
}

.validate-date-field-root {
  display: flex;
  align-self: flex-start;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  border: none;
  outline: none;
  padding: 0 !important;
  margin: 0 !important;
  width: inherit;
  height: 100%;
}

.date-notchedOutline,
.date-notchedOutline:focus-within {
  border: none !important;
  outline: none !important;
}

.validate-date-field-root #date-field {
  color: var(--input-text-color) !important;
  box-shadow: none;
  text-indent: 8px;
  padding: 0 !important;
  margin: 0 !important;
  font-weight: var(--default-font-weight);
  border-radius: 5px;
  z-index: 999;
  letter-spacing: 0.7px;
  font-family: var(--default-font);
  height: inherit;
  background-color: transparent;
}

#date-field-label {
  font-family: var(--default-font);
  color: var(--input-label-color) !important;
  font-weight: var(--default-font-weight) !important;
  transform: translate(14px, 9px) scale(1);
}

#date-field-label.MuiInputLabel-shrink {
  color: var(--shrink-label-color) !important;
  transform: translate(9px, -18px) scale(0.75);
}

#date-field-adornment {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px 0 0 !important;
  padding: 5px !important;
  border-radius: 50%;
  background-color: transparent;
  transition: background-color 0.4s ease;
}

#date-field-adornment:hover {
  cursor: pointer;
  background-color: rgba(101, 188, 220, 0.38);
}

#date-input-adornment-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px 0 0 !important;
  padding: 5px !important;
}

#date-input-adornment-btn:hover {
  cursor: pointer;
  background-color: rgba(35, 180, 202, 0.38);
}

#date-input-adornment {
  color: var(--main-blue) !important;
  margin: 0 !important;
  padding: 0 !important;
  font-size: 25px;
}

#date-popper #date-desktop-paper {
  background-color: rgba(240, 240, 240, 0.992);
}

.MuiDateCalendar-root {
  background-color: transparent;
  outline: 2px solid var(--app-bar-bg) !important;
}

#date-calendar-header {
  background-color: var(--app-bar-bg) !important;
}

#date-calendar-header .MuiPickersCalendarHeader-label {
  color: var(--argus-blue-light) !important;
  font-family: var(--default-font);
  font-weight: 550;
}
#date-switch-view-icon {
  color: var(--argus-blue-light) !important;
}

#date-next-icon-btn.Mui-disabled #date-right-arrow,
#date-previous-icon-btn.Mui-disabled #date-left-arrow {
  opacity: 0.55;
}

#date-left-arrow,
#date-right-arrow {
  color: var(--argus-blue-light) !important;
}

.MuiDayCalendar-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
}

.MuiDayCalendar-slideTransition {
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
}

.MuiDayCalendar-slideTransition,
.MuiDayCalendar-slideTransition > * {
  position: static !important;
}

.MuiDayCalendar-monthContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 225px !important;
  margin: 0 !important;
}

.MuiDayCalendar-weekContainer {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  height: 35px !important;
  padding: 0 !important;
  margin: 0 !important;
}

#calendar-day {
  font-weight: 550 !important;
  color: rgba(68, 76, 80, 0.962) !important;
}

.MuiPickersDay-root.MuiPickersDay-dayWithMargin {
  height: 30px !important;
  width: 30px !important;
}

#calendar-day.Mui-selected {
  color: var(--btn-white-text) !important;
  background-color: rgb(60, 182, 207) !important;
  font-weight: var(--default-font-weight);
}

#calendar-day:not(.Mui-disabled):hover {
  background-color: rgba(0, 141, 169, 0.893) !important;
  color: var(--btn-white-text) !important;
  transition: all 0.3s ease;
}

#calendar-day.Mui-disabled {
  opacity: 0.5;
}

#calendar-day:nth-child(1),
#calendar-day:nth-child(7) {
  color: rgb(214, 40, 40) !important;
}

#calendar-day.MuiPickersDay-today:not(.Mui-selected) {
  color: rgb(36, 36, 36);
  border: 1px solid var(--app-bar-bg) !important;
  border-radius: 1px;
  padding: 2px 2px 2px 2px !important;
  transition: all 0.3s ease;
}

#calendar-day.MuiPickersDay-today:not(.Mui-selected):hover {
  background-color: rgba(53, 162, 184, 0.771) !important;
  color: var(--btn-white-text) !important;
  border: none;
  border-radius: 50%;
}

.MuiDayCalendar-header {
  display: flex;
  align-self: center !important;
  justify-content: center !important;
  align-items: center;
  min-width: 100% !important;
  margin: 0 !important;
  box-shadow: 0px 1px 4px 1px rgba(70, 70, 70, 0.329) !important;
}

.MuiDayCalendar-weekDayLabel {
  font-weight: var(--default-font-weight) !important;
  color: var(--app-bar-bg) !important;
  height: 40px !important;
}

.MuiDayCalendar-weekDayLabel:nth-of-type(1),
.MuiDayCalendar-weekDayLabel:nth-of-type(7) {
  color: rgb(214, 40, 40) !important;
}

.MuiYearCalendar-root {
  display: flex;
  justify-self: center;
  min-width: 100% !important;
}

.MuiPickersYear-yearButton {
  font-size: 17px !important;
  font-weight: var(--default-font-weight) !important;
}

.MuiPickersYear-yearButton:hover {
  background-color: rgba(53, 162, 184, 0.771) !important;
  color: var(--btn-white-text) !important;
}

.MuiPickersYear-yearButton.Mui-selected {
  background-color: rgb(69, 168, 188) !important;
  color: var(--btn-white-text) !important;
  font-weight: var(--default-font-weight);
}

.MuiMonthCalendar-root {
  display: flex;
  justify-self: center;
  min-width: 100% !important;
}

.MuiPickersMonth-monthButton {
  font-size: 18px !important;
  font-weight: var(--default-font-weight) !important;
}

.MuiPickersMonth-monthButton.Mui-disabled {
  opacity: 0.5 !important;
}

.MuiPickersMonth-monthButton:not(.Mui-disabled):hover {
  background-color: rgba(53, 162, 184, 0.771) !important;
  color: var(--btn-white-text) !important;
}

.MuiPickersMonth-monthButton {
  color: rgb(45, 45, 45) !important;
}

.MuiPickersMonth-monthButton.Mui-selected {
  background-color: rgb(69, 168, 188) !important;
  color: var(--btn-white-text) !important;
  font-weight: var(--default-font-weight);
}

.modal-text-container.modal-bool-container {
  display: flex;
  align-items: center;
  justify-content: space-around !important;
  align-self: flex-start !important;
  width: 455px;
  height: 90px;
  margin: 5px 0;
  position: relative;
  left: 32px;
}

#locked-input-container-bool {
  display: flex;
  justify-content: space-between;
  pointer-events: none;
  min-width: 255px;
}

.modal-radio-btn-label {
  font-size: 20px;
  font-weight: var(--default-font-weight) !important;
  font-family: var(--default-font) !important;
  color: var(--label-color) !important;
  text-shadow: var(--form-label-text-shadow) !important;
  pointer-events: none !important;
  user-select: none !important;
  margin-left: 5px !important;
}

.modal-radio-btn-root#modal-radio-label-true,
.modal-radio-btn-root#modal-radio-label-false {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
}

.modal-radio-btn-root#modal-radio-label-true {
  width: 65px;
}

.modal-radio-btn-root#modal-radio-label-false {
  width: 58px;
}

.modal-radio-grouping {
  display: flex;
  align-items: center;
  justify-content: center !important;
  gap: 30px;
  padding: 5px 0;
  margin: 0;
}

.modal-radio-grouping.radio-group-row {
  display: flex;
  align-items: center !important;
  justify-content: space-between !important;
  gap: 30px;
}

.modal-radio-btn-root {
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  justify-content: center;
  min-width: max-content;
  margin: 0 0 0 0 !important;
  padding: 0 !important;
  height: 40px;
  color: var(--label-color) !important;
  gap: 8px;
}

#modal-radio-label-true,
#modal-radio-label-false {
  width: fit-content;
}

.modal-radio-btn-root .modal-btn-true,
.modal-radio-btn-root .modal-btn-false {
  pointer-events: auto !important;
  margin: 0;
  padding: 0;
  border-radius: 3px;
  border: none !important;
  outline: none !important;
  color: var(--label-color) !important;
}

.modal-btn-true .PrivateSwitchBase-input,
.modal-btn-false .PrivateSwitchBase-input {
  border: none !important;
  outline: none !important;
}

.modal-btn-icon-true {
  background-color: rgb(244, 244, 244);
  outline: 1px solid rgb(244, 244, 244) !important;
  border: none !important;
  border-color: transparent !important;
  border-radius: 3px;
  fill: rgba(244, 244, 244);
  box-shadow:
    rgba(0, 0, 0, 0.06) 0px 2px 1px,
    rgba(0, 0, 0, 0.09) 0px 4px 2px,
    rgba(0, 0, 0, 0.09) 0px 2px 4px,
    rgba(0, 0, 0, 0.09) 0px 4px 8px,
    rgba(0, 0, 0, 0.09) 0px 6px 5px;
  margin: 0;
  padding: 0;
  z-index: 1 !important;
}

.modal-btn-icon-false {
  background-color: rgb(244, 244, 244);
  outline: 1px solid rgb(244, 244, 244) !important;
  border: 0 !important;
  border-radius: 3px;
  fill: rgb(244, 244, 244);
  color: rgb(244, 244, 244);
  box-shadow:
    rgba(0, 0, 0, 0.06) 0px 2px 1px,
    rgba(0, 0, 0, 0.09) 0px 4px 2px,
    rgba(0, 0, 0, 0.09) 0px 2px 4px,
    rgba(0, 0, 0, 0.09) 0px 4px 8px,
    rgba(0, 0, 0, 0.09) 0px 6px 5px;
  margin: 0;
  padding: 0;
  z-index: 1 !important;
}

.modal-btn-false .modal-btn-icon-false {
  outline: 1px solid rgb(244, 244, 244) !important;
  color: rgb(244, 244, 244);
}

.modal-btn-false.modal-btn-checked .modal-btn-icon-false {
  background-color: rgb(225, 36, 36);
  outline: 1px solid rgb(225, 36, 36) !important;
  border: none;
  border-radius: 3px;
  margin: 0;
  padding: 0;
  fill: rgb(242, 242, 242);
}

.modal-btn-true.modal-btn-checked .modal-btn-icon-true {
  fill: rgb(79, 203, 30) !important;
  color: rgb(79, 203, 30) !important;
  outline: 1px solid rgb(79, 203, 30) !important;
  border: none;
  border-radius: 3px;
  margin: 0;
  padding: 0;
}

.modal-btn-true input,
.modal-btn-false input {
  border: none !important;
  outline: none !important;
}

.modal-switch-container.text-area-switch {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 815px;
  margin: 10px 0px;
}

.modal-text-input.modal-text-area-input {
  display: flex;
  align-items: center;
  justify-content: space-around !important;
  width: 815px;
  height: 140px !important;
  margin: 5px 0px;
}

.text-area-input {
  width: 500px !important;
  height: 110px !important;
  margin: 0px;
  padding: 5px;
  font-size: 18px;
  font-family: var(--default-font);
}

.text-area-input:disabled {
  color: rgba(96, 96, 96, 0.72);
}

#locked-text-area-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 658px;
}

.modal-message-container#modal-message-ta {
  display: flex;
  align-items: center;
  justify-content: space-around !important;
  width: 800px;
  margin: 10px 0px;
  height: 125px;
}

#scopeOfWork-message {
  position: relative;
  right: 90px;
}

#locked-input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 545px;
}

span.lock-form-label {
  color: var(--label-color) !important;
  font-family: var(--default-font) !important;
  font-weight: var(--default-font-weight) !important;
  font-size: 19px;
}

.modal-checkbox {
  pointer-events: all !important;
  cursor: pointer;
  padding: 0;
  margin: 0 !important;
  transition: background-color 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  height: 42px;
  width: 42px;
}

.modal-checkbox.locked:hover {
  background-color: rgba(255, 54, 54, 0.209);
}

.modal-checkbox.unlocked:hover {
  background-color: rgba(110, 255, 110, 0.136);
}

#locked-icon {
  color: var(--btn-red);
  height: 27px;
  width: 27px;
  margin: 0;
  padding: 0;
}

#unlocked-icon {
  color: rgb(0, 199, 10);
  height: 27px;
  width: 27px;
  margin: 0;
  padding: 0;
}

.modal-btns {
  display: flex;
  justify-content: space-around;
  width: 235px;
  margin: 15px 0px 15px 0px;
}

#close-btn {
  background-color: rgb(199, 31, 31);
  width: 75px;
  font-weight: var(--default-font-weight);
}

#close-btn:hover {
  background-color: rgb(214, 17, 17);
}

#save-btn {
  background-color: rgb(79, 203, 30);
  width: 75px;
  font-weight: var(--default-font-weight);
}

#save-btn:hover {
  background-color: rgb(36, 212, 36);
}

@media screen and (max-width: 1449px) {
  .text-input-root {
    width: 406px !important;
  }
  .text-input {
    width: 406px !important;
  }
  .text-input-root .MuiOutlinedInput-root {
    width: 406px !important;
  }
  .text-input-root .MuiInputBase-root {
    width: 406px !important;
  }
  .text-input-root .MuiInputBase-formControl {
    width: 406px !important;
  }
}

@media screen and (min-width: 1450px) {
  .text-input-root {
    width: 415px;
    height: 40px;
  }
  .text-input {
    width: 415px;
  }
  .text-input-root .MuiOutlinedInput-root {
    width: 415px;
  }
  .text-input-root .MuiInputBase-root {
    width: 415px;
  }
  .text-input-root .MuiInputBase-formControl {
    width: 415px !important;
  }
}
