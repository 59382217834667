.create-client-container {
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  min-width: 100vw;
  min-height: calc(100vh - 160px);
  max-height: 100%;
  position: relative;
  top: 50px;
  gap: 50px;
  overflow: visible;
}

#create-client {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 1150px;
  min-width: 1000px;
  height: fit-content;
  min-height: 450px;
  background-color: var(--main-blue);
  padding: 0 0 10px 0;
  margin: 15px 0 10px 0;
  outline: 4px solid var(--outline-color);
  box-shadow: var(--input-container-box-shadow);
  border-radius: 2px;
}

#edit-client {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 1150px;
  min-width: 1000px;
  height: fit-content;
  min-height: 650px;
  background-color: var(--main-blue);
  padding: 0 0 10px 0;
  margin: 15px 0 10px 0;
  outline: 4px solid var(--outline-color);
  box-shadow: var(--input-container-box-shadow);
  border-radius: 2px;
}

#client-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-bottom: 4px solid var(--outline-color);
}

#create-client-header {
  font-size: 32px;
  margin-bottom: 15px;
  text-shadow: 0px 1px 2px rgb(53, 53, 53);
  width: 100%;
  text-align: center;
  font-family: var(--default-font);
  font-weight: var(--font-weight-bold);
  color: var(--main-header-color);
  text-shadow: var(--header-text-shadow);
}

#create-client-hr {
  border: none;
  border-bottom: 4px solid var(--outline-color);
  width: 100%;
  margin-bottom: 10px;
}

.create-client-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: 0 0 15px 0;
  gap: 15px;
}

.inputs-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.input-info-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  gap: 15px;
  font-family: var(--default-font);
  margin: 10px 0;
  padding: 5px 0;
}

.input-label {
  font-size: 22px;
  font-weight: var(--default-font-weight) !important;
  font-family: var(--default-font) !important;
  color: var(--label-color) !important;
  text-shadow: var(--form-label-text-color) !important;
  margin-right: auto;
  pointer-events: none !important;
  user-select: none !important;
}

#input-form {
  box-shadow: var(--input-box-shadow);
  background-color: var(--input-white-bg);
  border-radius: 5px;
  border: none;
  outline: none;
  height: 40px;
  width: 450px;
  margin: 0;
}

.input-text-root {
  display: flex;
  align-self: flex-start;
  align-items: center;
  justify-content: space-between;
  border: none;
  outline: none;
  width: 100%;
  border-radius: 5px;
  margin: 0 !important;
  padding: 0 !important;
  height: 100%;
}

.input-text-notched-outline {
  border: none !important;
  outline: none !important;
}

.input-text-notched-outline:focus-within {
  border: none !important;
  outline: none !important;
}

.input-text-input#input-text {
  color: var(--input-text-color) !important;
  font-size: 18px;
  box-shadow: none;
  text-indent: 8px;
  padding: 0 !important;
  margin: 0 !important;
  font-weight: var(--default-font-weight);
  border-radius: 5px;
  z-index: 999;
  letter-spacing: 0.7px;
  font-family: var(--default-font);
  height: inherit;
  background-color: transparent;
}

#form-label {
  font-family: var(--default-font);
  color: var(--input-label-color) !important;
  font-weight: var(--default-font-weight) !important;
  transform: translate(14px, 9px) scale(1);
}

#form-label.input-label-shrink {
  color: var(--shrink-label-color) !important;
  transform: translate(3px, -21px) scale(0.75) !important;
}

.input-clear-adornment#clear-adornment {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px 0 0 !important;
  border-radius: 50%;
  width: 35px;
  height: 35px;
}

#clear-input {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  margin: 0;
  padding: 10px;
  pointer-events: auto;
}

.input-clear-adornment#clear-adornment:hover {
  cursor: pointer;
  background-color: rgba(255, 88, 88, 0.276);
}

.close-icon {
  font-size: 25px !important;
  fill: var(--close-icon-color);
  color: var(--close-icon-color);
  stroke: var(--close-icon-color);
  margin: 0;
  padding: 0;
}

.helper-text#helper-text {
  font-size: 14px;
  font-family: var(--default-font);
  font-weight: var(--font-weight-bold);
  color: var(--p-yellow);
  opacity: 1;
  text-indent: 0;
  margin: 0;
  padding: 0;
  text-align: center;
  line-height: 1.1;
  position: absolute;
  top: 45px;
}

.submit-new-client-btn#submit-new-client-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 34px;
  margin: 5px 0;
  padding: 0;
  line-height: 1;
  background-color: var(--btn-green);
  color: var(--btn-white-text);
  font-size: 16px;
  font-family: var(--default-font) !important;
  font-weight: var(--font-weight-bold);
}

.submit-new-client-btn#submit-new-client-btn[disabled] {
  background-color: var(--disabled-btn-bg);
  color: var(--disabled-btn-color);
  pointer-events: auto;
}

.submit-new-client-btn#submit-new-client-btn:hover {
  cursor: pointer;
  background-color: var(--btn-green-hover);
}

.submit-new-client-btn#submit-new-client-btn[disabled]:hover {
  cursor: not-allowed !important;
  background-color: var(--disabled-btn-bg);
}

.existing-clients-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 420px;
  margin: 0;
  max-height: calc(100vh - 238px);
  background-color: var(--main-blue);
  position: relative;
  box-shadow: var(--small-container-box-shadow);
  top: 12px;
  overflow-y: scroll;
  border-radius: 5px;
  border: 4px solid var(--outline-color);
}

#existing-client-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 5px;
  position: sticky;
  top: 0px;
  border-bottom: 4px solid var(--outline-color);
  background-color: var(--main-blue);
  z-index: 999;
}

#existing-clients-header {
  font-size: 24px;
  font-family: var(--default-font);
  font-weight: var(--font-weight-bold);
  color: var(--main-header-color);
  text-shadow: var(--header-text-shadow);
  margin: 5px 0;
  padding: 0;
}

#existing-clients-hr {
  border: none;
  border-bottom: 4px solid var(--outline-color);
  width: 100%;
  margin: 0 0 5px 0;
}

.clients-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 92%;
  height: 100%;
  gap: 10px;
  margin: 0;
}

.client-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 145px;
  width: 100%;
  border: 2px solid var(--outline-color);
  border-radius: 5px;
  margin: 0;
  padding: 2px;
  gap: 15px;
  background-color: var(--argus-blue-darker);
}

.client-data {
  color: var(--argus-white);
  font-size: 14px;
  font-family: var(--default-font);
  font-weight: var(--default-font-weight);
  margin: 0;
  padding: 0;
}

.client-data#name {
  color: var(--argus-white);
  font-size: 16px;
  font-family: var(--default-font);
  font-weight: var(--font-weight-bold);
  margin: 0;
  padding: 0;
}

.edit-client-btn#edit-client-btn {
  background-color: var(--argus-blue-light);
  color: var(--argus-white);
  font-size: 14px;
  font-family: var(--default-font);
  font-weight: var(--font-weight-bold);
  width: 60px;
  height: 28px;
  margin: 0;
  padding: 0 0 0 0;
}

.edit-client-btn#edit-client-btn:hover {
  cursor: pointer;
  background-color: var(--main-blue);
}

.update-client-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 450px;
  gap: 15px;
}

.update-client-btn#update-client-btn {
  background-color: var(--btn-green);
  color: var(--btn-white-text);
  font-size: 14px;
  font-family: var(--default-font);
  font-weight: var(--font-weight-bold);
  width: 75px;
  height: 35px;
  margin: 0 0 5px 0;
  padding: 0;
}

.update-client-btn#update-client-btn:hover {
  cursor: pointer;
  background-color: var(--btn-green-hover);
}

.cancel-edit-client-btn#cancel-edit-client-btn {
  background-color: var(--btn-red);
  color: var(--btn-white-text);
  font-size: 14px;
  font-family: var(--default-font);
  font-weight: var(--font-weight-bold);
  width: 80px;
  height: 35px;
  margin: 0 0 5px 0;
  padding: 0;
}

.cancel-edit-client-btn#cancel-edit-client-btn:hover {
  cursor: pointer;
  background-color: var(--btn-red-hover);
}

@media screen and (width < 1450px) {
  #create-client {
    max-width: 800px;
    min-width: 750px;
    min-height: 585px;
    max-height: 585px;
  }

  #edit-client {
    max-width: 800px;
    min-width: 750px;
    min-height: 635px;
    max-height: 635px;
  }

  #client-header {
    height: 50px;
    margin-bottom: 8px;
  }

  #create-client-header {
    font-size: 24px;
    height: 30px;
    margin: 0;
    margin: 5px 0 5px 0;
    text-align: center;
  }

  #create-client-hr {
    margin-bottom: 5px;
  }

  .create-client-form-container {
    gap: 10px;
    margin: 0 0 10px 0;
  }

  .input-info-container {
    height: 50px;
    margin: 7px 0;
    padding: 3px 0;
  }

  .input-label {
    font-size: 20px;
  }

  #input-form {
    height: 38px;
    width: 400px;
  }

  .input-text-input#input-text {
    font-size: 15px;
    text-indent: 8px;
  }

  #form-label {
    transform: translate(12px, 8px) scale(0.9);
  }

  #form-label.input-label-shrink {
    transform: translate(3px, -18px) scale(0.7) !important;
  }

  .helper-text#helper-text {
    font-size: 13px;
    top: 43px;
    line-height: 1;
  }

  .submit-new-client-btn#submit-new-client-btn {
    width: 72px;
    height: 28px;
    margin: 5px 0;
    font-size: 13px;
  }

  .input-clear-adornment#clear-adornment {
    width: 28px;
    height: 28px;
  }

  #clear-input {
    width: 28px;
    height: 28px;
  }

  .close-icon {
    font-size: 22px !important;
  }

  .text-info-circ.client-info-circ {
    height: 26px;
    width: 26px;
  }

  .existing-clients-container {
    max-height: 592px;
    width: 360px;
  }

  .existing-clients-container#taller {
    max-height: 660px;
  }

  #existing-clients-header {
    font-size: 20px;
    margin: 5px 0;
  }

  .clients-list {
    gap: 8px;
  }

  .client-item {
    gap: 8px;
    max-height: 105px;
    height: fit-content;
    min-height: 90px;
  }

  .client-data {
    font-size: 14px;
  }

  .client-data#name {
    font-size: 17px;
  }

  .edit-client-btn#edit-client-btn {
    font-size: 12px;
    min-width: 48px !important;
    width: 52px;
    height: 24px;
    margin: 5px 0 0 0;
  }

  .update-client-btns {
    width: 400px;
    gap: 10px;
  }

  .update-client-btn#update-client-btn {
    font-size: 12px;
    width: 65px;
    height: 30px;
  }

  .cancel-edit-client-btn#cancel-edit-client-btn {
    font-size: 12px;
    width: 70px;
    height: 30px;
  }
}

@media screen and (1450px <= width < 1750px) {
  #create-client {
    max-width: 1000px;
    min-width: 950px;
  }

  #client-header {
    height: 50px;
    margin-bottom: 10px;
  }

  #create-client-header {
    font-size: 28px;
  }

  #create-client-hr {
    margin-bottom: 8px;
  }

  .create-client-form-container {
    gap: 12px;
    margin: 0 0 12px 0;
  }

  .input-info-container {
    height: 53px;
    margin: 8px 0;
    padding: 4px 0;
  }

  .input-label {
    font-size: 21px;
  }

  #input-form {
    height: 42px;
    width: 425px;
  }

  .input-text-input#input-text {
    font-size: 16px;
    text-indent: 8px;
  }

  #form-label {
    transform: translate(13px, 9px) scale(0.95);
  }

  #form-label.input-label-shrink {
    transform: translate(3px, -20px) scale(0.8) !important;
  }

  .helper-text#helper-text {
    font-size: 14px;
    top: 44px;
    line-height: 1.1;
  }

  .submit-new-client-btn#submit-new-client-btn {
    width: 78px;
    height: 30px;
    margin: 5px 0;
    font-size: 15px;
  }

  .input-clear-adornment#clear-adornment {
    width: 30px;
    height: 30px;
  }

  #clear-input {
    width: 30px;
    height: 30px;
  }

  .close-icon {
    font-size: 23px !important;
  }

  .text-info-circ.client-info-circ {
    height: 28px;
    width: 28px;
  }

  .existing-clients-container {
    width: 340px;
    max-height: 645px;
  }

  #existing-clients-header {
    font-size: 22px;
    margin: 5px 0;
  }

  .clients-list {
    gap: 10px;
  }
}

@media screen and (1750px <= width) {
  .existing-clients-container {
    width: 360px;
    max-height: 730px;
  }
}
